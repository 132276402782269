import _ from 'lodash';
import { isStage, isProd } from '@/core/configs';
import { mainMenu } from '@/core/configs/basic.config';

export default {
  computed: {
    getUserRoles() {
        return this.$store.getters['auth/userRoles'];
    },
  },
  methods: {
    getItems(rootItem) {
      return rootItem.filter((nestedItem) => nestedItem.slug !== 'nav_tv_statistics' && nestedItem.slug !== 'nav_stories_statistics' && !nestedItem.isDisabled && _.intersection(nestedItem.role, this.getUserRoles).length !== 0);
    },
    getItemsList(rootItem) {
      return rootItem.filter((nestedItem) => !nestedItem.isDisabled && _.intersection(nestedItem.role, this.getUserRoles).length !== 0);
    },
    filteredMainMenu() {
        return mainMenu.reduce((acc, rootItem) => {
          if (!rootItem.isDisabled && _.intersection(this.getUserRoles, rootItem.role).length !== 0) {
            if (isStage || isProd) {
              acc.push({
                ...rootItem,
                items: this.getItems(rootItem.items),
              });
            } else {
              acc.push({
                ...rootItem,
                items: this.getItemsList(rootItem.items),
              });
            }
          }
          return acc;
        }, []);
    },
    updatePermissions() {
      const routes = this.$router.options.routes[3].children;
      const permissions = routes.filter((item) => !item.isDisabled && _.intersection(item.role, this.getUserRoles).length !== 0).map(obj => obj.name);
      this.$store.dispatch('auth/setPermissions', permissions);
      return permissions;
    },
  },
};
