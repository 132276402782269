<template>
  <div
    class="header header-fixed"
  >
    <div class="container-fluid d-flex align-items-center justify-content-between">
      <div
        class="header-menu-wrapper header-menu-wrapper-left"
        ref="kt_header_menu_wrapper"
      >
        <div
          id="kt_header_menu"
          ref="kt_header_menu"
          class="header-menu header-menu-mobile header-menu-layout-default"
        >
        </div>
      </div>

      <div class="topbar-item">
        <div class="d-flex align-items-center">
          <span class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">Hi,</span>
          <span class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">Kidjo User</span>
          <a
            href="#" class="btn btn-secondary btn-rounded btn-icon btn-sm"
            @click.prevent="onLogout"
          >
            <i class="mdi mdi-logout" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Header',
    methods: {
      onLogout() {
        this.$store.dispatch('auth/logout')
          .then(() => this.$router.push({ name: 'login' }))
          .catch(err => err);
      },
    },
  };
</script>
