<template>
  <div class="page-loader page-loader-logo">
    <img
      alt="Logo"
      :src="logo"
      width="100"
    />
    <div class="spinner" v-bind:class="spinnerClass || 'spinner-primary'"></div>
  </div>
</template>

<script>
  export default {
    name: 'AppLoader',
    props: {
      logo: String,
      spinnerClass: String
    }
  };
</script>
