import { createRouter, createWebHashHistory } from 'vue-router';
import store from '@/core/store';
// eslint-disable-next-line import/no-cycle
import { demoGuard } from '@/core/router/traps';
import AppBasic from '@/core/router/routes/app.basic';
import MiscDashboard from '@/core/router/routes/misc.dashboard';
import TVOwners from '@/core/router/routes/tv.owners';
import TVLicenses from '@/core/router/routes/tv.licenses';
import TVFolders from '@/core/router/routes/tv.folders';
import TVVideos from '@/core/router/routes/tv.videos';
import TVStatistics from '@/core/router/routes/tv.statistics';
import StoriesOwners from '@/core/router/routes/stories.owners';
import StoriesLicenses from '@/core/router/routes/stories.licenses';
import StoriesFolders from '@/core/router/routes/stories.folders';
import StoriesBooks from '@/core/router/routes/stories.books';
import StoriesStatistics from '@/core/router/routes/stories.statistics';
import GeneralUsers from '@/core/router/routes/general.users';
import GeneralCoupons from '@/core/router/routes/general.coupons';

const router = createRouter({

  history: createWebHashHistory(),
  routes: [
    ...AppBasic,
    {
      path: '/',
      redirect: '/dashboard',
      component: () => import('@/components/layouts/LoggedFrame'),
      children: [
        ...MiscDashboard,
        ...TVOwners,
        ...TVLicenses,
        ...TVFolders,
        ...TVVideos,
        ...TVStatistics,
        ...StoriesOwners,
        ...StoriesLicenses,
        ...StoriesFolders,
        ...StoriesBooks,
        ...StoriesStatistics,
        ...GeneralUsers,
        ...GeneralCoupons,
      ]
    },
  ]

});

router.beforeEach((to, from, next) => {
  demoGuard(to, from, next);
  store.dispatch('config/setServiceKey', to.meta.parentGroup);
});

export default router;
