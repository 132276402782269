import axios from 'axios';

const baseUrl = import.meta.env.VITE_API_STORIES;
const headers = {
  'Content-Type': 'application/json; Charset=UTF-8',
};

export default {
  login(data) {
    return axios({
      method: 'post',
      url: baseUrl + '/users/login',
      headers,
      data,
    });
  }
};
