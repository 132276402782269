export default [
  {
    path: '/dashboard',
    name: 'dashboard',
    role: ['admin', 'developer'],
    meta: {
      loadState: true,
      requiresAuth: true,
      isAuthView: false,
    },
    component: () => import('@/views/misc/Dashboard'),
  },
];
