import axios from 'axios';

const baseUrl = import.meta.env.VITE_API_STORIES;
const headers = {
  'Content-Type': 'application/json; Charset=UTF-8',
};

export default {
  getBooks(options = null) {
    let params = null;

    if (options) {
      const {
        activity = '',
        premium = '',
        thematic = '',
        language = '',
        license = '',
        offset = 0,
        limit = 10,
        keyword = '',
        type = '',
        sort = '',
      } = options;

      params = {
        offset,
        fetchLimit: limit,
        licenseId: license,
        categoryId: thematic,
        languageId: language,
        isActive: activity,
        isPremium: premium,
        search: keyword,
      };

      if (sort) {
        params.sortColumns = type;
        params.sortColumnOrders = sort;
      }
    }

    return axios({
      method: 'get',
      url: `${baseUrl}/books`,
      headers,
      params,
    });
  },
  getBook(id) {
    const params = {
      bookId: id,
    };

    return axios({
      method: 'get',
      url: `${baseUrl}/book-bundle`,
      headers,
      params,
    });
  },
  createBook(data) {
    return axios({
      method: 'post',
      url: baseUrl + '/book-bundle',
      headers,
      data,
    });
  },
  updateBook(data) {
    return axios({
      method: 'put',
      url: baseUrl + '/book-bundle',
      headers,
      data,
    });
  },
  updateBookStatus(id, status) {
    const params = {
      bookId: id,
      isActive: status,
    };

    return axios({
      method: 'put',
      url: `${baseUrl}/book/status`,
      headers,
      params,
    });
  },
  switchBookPremiumStatus(id, status) {
    const params = {
      bookId: id,
      isPremium: status,
    };

    return axios({
      method: 'put',
      url: `${baseUrl}/book-bundle/premium`,
      headers,
      params,
    });
  },
  getPageBundle(id) {
    const params = {
      bookLanguageId: id,
    };

    return axios({
      method: 'get',
      url: `${baseUrl}/page-bundle`,
      headers,
      params,
    });
  },
  updatePageBundle(data) {
    return axios({
      method: 'put',
      url: `${baseUrl}/page-bundle`,
      headers,
      data,
    });
  },
  detachBook(id) {
    const params = {
      bookId: id,
    };

    return axios({
      method: 'delete',
      url: `${baseUrl}/book/folders`,
      headers,
      params,
    });
  }
};
