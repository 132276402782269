import initialState from '@/core/store/initialState';
import { isObj, isArr, isNull } from '@/core/utils';

/**
* @description Is valid
* @param users
* @returns {boolean}
*/
const isValid = (users) => isObj(users)
  && isNull(users.filters)
  && isArr(users.items)
  && isNull(users.size)
  && isObj(users.activeItem);

/**
* @description Init state
* @param initialState
* @returns {*}
*/
const initState = (initialState) => {
  if (!isValid(initialState)) {
    throw Error('Invalid initial stories users state');
  }

  const { items, size, activeItem, filters } = initialState;
  return {
    items,
    size,
    activeItem,
    filters,
  };
};

/**
* @description Getters
* @type {*}
*/
export const getters = {
  items: ({ items }) => items,
  size: ({ size }) => size,
  activeItem: ({ activeItem }) => activeItem,
  filters: ({ filters }) => filters,
};

const actions = {
  setFilters: ({ commit }, nextFilters) => {
    commit('FILTER', nextFilters);
  },
  reset: ({ commit }) => (
    commit('SET', initialState.generalUsers)
  ),
  set: ({ commit }, users) => {
    commit('SET', users);
  },
};

const mutations = {
  SET(state, users) {
    state.token = users.token;
    state.user = users.user;
    state.userRoles = users.userRoles;
    state.permissions = users.permissions;
  },
  FILTER(state, filters) {
    state.filters = filters;
  },
};

export default initialState => ({
  namespaced: true,
  state: initState(initialState),
  getters,
  actions,
  mutations,
});
