<template>
  <div
    :class="['scrolltop', {'scrolltop--active': scrollY > 200}]"
    @click="scrollToTop"
  >
    <i class="mdi mdi-arrow-up" />
  </div>
</template>

<script>
  export default {
    name: 'ScrollTopBtn',
    data() {
      return {
        scrollTimer: 0,
        scrollY: 0,
      };
    },
    created() {
      window.addEventListener('scroll', this.handlePageScroll);
    },
    methods: {
      handlePageScroll() {
        if (this.scrollTimer) return;

        this.scrollTimer = setTimeout(() => {
          this.scrollY = window.scrollY;
          clearTimeout(this.scrollTimer);
          this.scrollTimer = 0;
        }, 100);
      },
      scrollToTop() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      },
    },
  };
</script>
