import { createApp } from 'vue';
import router from '@/core/router';
import vuetify from '@/core/plugins/vuetify';
import '@/core/plugins/axios';
import base from '@/core/plugins/base';
import store from '@/core/store';
import PortalVue from 'portal-vue';
import App from '@/views/App';

// Global components

import LoggedFrame from '@/components/layouts/LoggedFrame';
import SimpleTable from '@/components/tables/SimpleTable';
import DataTable from '@/components/tables/DataTable';
import Pagination from '@/components/Pagination';
import Editor from '@/components/editor/Editor';
import SimpleDialog from '@/components/dialogs/SimpleDialog';
import Loader from '@/components/Loader';
import ItemsCounter from '@/components/ItemsCounter';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import '@/assets/scss/metronic.scss';
import '@mdi/font/css/materialdesignicons.css';

// Mixins

import fileUpload from '@/mixins/fileUpload';
import filterEngine from '@/mixins/filterEngine';
import generateTestClass from '@/mixins/generateTestClass';

// Vue.config.productionTip = false;

const app = createApp(App);

app.use(vuetify)
  .use(router)
  .use(store)
  .use(base)
  .use(PortalVue);

app.component('LoggedFrame', LoggedFrame)
  .component('SimpleTable', SimpleTable)
  .component('DataTable', DataTable)
  .component('Pagination', Pagination)
  .component('Editor', Editor)
  .component('SimpleDialog', SimpleDialog)
  .component('Loader', Loader)
  .component('ItemsCounter', ItemsCounter)
  .component('VueDatePicker', VueDatePicker);

app
  .mixin(generateTestClass)
  .mixin(fileUpload)
  .mixin(filterEngine);

app.mount('#app');
