<template>
  <div v-if="isShowing" class="card-overlay">
    <div class="spinner spinner-primary" />
  </div>
</template>

<script>
  export default {
    name: 'Loader',
    props: {
      isShowing: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .card-overlay {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, .7);
    border-radius: 6px;
  }
</style>
