<template>
  <div class="my-3 mx-2 h5 font-weight-black">
    <slot name="items-counter" />
  </div>
</template>
<script>
  export default {
    name: 'ItemsCounter',
  };
</script>
