import initialState from '@/core/store/initialState';
import { isArr, isObj } from '@/core/utils';
// eslint-disable-next-line import/no-cycle
import ConfigService from '@/core/services/basic.config';

/**
* @description Is valid
* @param config
* @returns {boolean}
*/
const isValid = (config) => isObj(config)
  && isArr(config.regions)
  && isArr(config.countries)
  && isArr(config.thematics)
  && isArr(config.roles)
  && isArr(config.languages)
  && isArr(config.countryRegionList);

/**
* @description Init state
* @param initialState
* @returns {*}
*/
const initState = (initialState) => {
  if (!isValid(initialState)) {
    throw Error('Invalid initial app config state');
  }

  const { regions, countries, thematics, languages, roles, countryRegionList } = initialState;
  return {
    regions,
    countries,
    thematics,
    languages,
    roles,
    countryRegionList,
  };
};

/**
* @description Getters
* @type {*}
*/
export const getters = {
  all: (config) => config,
  serviceKey: ({ serviceKey }) => serviceKey,
  regions: ({ regions }) => regions,
  countries: ({ countries }) => countries,
  roles: ({ roles }) => roles,
  thematics: ({ thematics }) => thematics,
  languages: ({ languages }) => languages,
  countryRegionList: ({ countryRegionList }) => countryRegionList
};

const actions = {
  setServiceKey: ({ commit }, key) => {
    commit('SERVICEKEY', key);
  },
  getAppConfigs: ({ commit }, token) => {
    ConfigService.getAppConfigs(token)
      .then(({ data }) => {
        const nextConfigs = { ...data };

        commit('SET', nextConfigs);

        return nextConfigs;
      })
      .catch((err) => err);
  },
  getUserRoles: ({ commit }, token) => {
    ConfigService.getUserRoles(token)
      .then(({ data }) => {
        commit('USERROLES', data);
        return data;
      })
      .catch((err) => err);
  },
  getAccountCountriesList: ({ commit }, token) => {
    ConfigService.getAccountCountriesList(token)
      .then(({ data }) => {
        commit('COUNTRIESLIST', data);
        return data;
      })
      .catch((err) => err);
  },
  reset: ({ commit }) => (
    commit('SET', initialState.storiesBooks)
  ),
  set: ({ commit }, config) => {
    commit('SET', config);
  }
};

const mutations = {
  SET(state, config) {
    state.regions = config.regions;
    state.countries = config.countries;
    state.thematics = config.thematics;
    state.languages = config.languages;
  },
  USERROLES(state, config) {
    state.roles = config.roles;
  },
  COUNTRIESLIST(state, countryRegionList) {
    state.countryRegionList = countryRegionList;
  },
  SERVICEKEY(state, serviceKey) {
    state.serviceKey = serviceKey;
  },
};

export default initialState => ({
  namespaced: true,
  state: initState(initialState),
  getters,
  actions,
  mutations,
});
