import axios from 'axios';

const baseUrl = import.meta.env.VITE_API_STORIES;
const headers = {
  'Content-Type': 'application/json; Charset=UTF-8',
};

export default {
  getOwnersList() {
    return axios({
      method: 'get',
      url: `${baseUrl}/owners`,
      headers,
    });
  },
  getOwners(options = null) {
    let params = null;

    if (options) {
      const {
        offset = 0,
        limit = 10,
        keyword = '',
        type = '',
        sort = '',
      } = options;

      params = {
        offset,
        fetchLimit: limit,
        search: keyword,
      };

      if (sort) {
        params.sortColumns = type;
        params.sortColumnOrders = sort;
      }
    }

    return axios({
      method: 'get',
      url: `${baseUrl}/owners`,
      headers,
      params,
    });
  },
  getOwner(id) {
    const params = {
      ownerId: id,
    };

    return axios({
      method: 'get',
      url: `${baseUrl}/owner`,
      headers,
      params,
    });
  },
  createOwner(data) {
    return axios({
      method: 'post',
      url: `${baseUrl}/owner`,
      headers,
      data,
    });
  },
  updateOwner(data) {
    return axios({
      method: 'put',
      url: `${baseUrl}/owner`,
      headers,
      data,
    });
  },
  deleteOwner(id) {
    const params = {
      ownerId: id,
    };

    return axios({
      method: 'delete',
      url: `${baseUrl}/owner`,
      headers,
      params,
    });
  },
  getOwnersLicenses() {
    return axios({
      method: 'get',
      url: `${baseUrl}/owners/licenses`,
      headers,
    });
  }
};
