import axios from 'axios';

const baseUrl = import.meta.env.VITE_API_STORIES;
const uploadImageBaseUrl = import.meta.env.VITE_API_BOOK_STORIES;
const headers = {
  'Content-Type': 'application/json; Charset=UTF-8',
};
const multiPartHeaders = {
  'Content-Type': 'multipart/form-data',
};
const FOLDER_IMAGE_URL = '/s3/handler/folder/images';
const BOOK_IMAGE_URL = '/s3/upload';
export default {
  getFolders(options = null) {
    let params = null;

    if (options) {
      const {
        activity = '',
        language = '',
        license = '',
        offset = 0,
        limit = 10,
        keyword = '',
        type = '',
        sort = '',
      } = options;

      params = {
        offset,
        fetchLimit: limit,
        licenseId: license,
        languageId: language,
        isActive: activity,
        search: keyword,
      };

      if (sort) {
        params.sortColumns = type;
        params.sortColumnOrders = sort;
      }
    }

    return axios({
      method: 'get',
      url: `${baseUrl}/folders`,
      headers,
      params,
    });
  },
  // Check if we can depricate this
  getSlimFolders() {
    return axios({
      method: 'get',
      url: `${baseUrl}/folders/slim`,
      headers,
    });
  },
  getFolder(id) {
    const params = {
      folderId: id,
    };

    return axios({
      method: 'get',
      url: `${baseUrl}/folder`,
      headers,
      params,
    });
  },
  createBookImage(data) {
      return axios({
        method: 'post',
        url: `${baseUrl}${BOOK_IMAGE_URL}`,
        multiPartHeaders,
        data,
      });
   },
  createFolderImage(data, folderId, language) {
    return axios({
      method: 'post',
      url: `${baseUrl}${FOLDER_IMAGE_URL}`,
      multiPartHeaders,
      data,
      params: {
        language
      },
    });
  },
  createFolder(data) {
    return axios({
      method: 'post',
      url: baseUrl + '/folder',
      headers,
      data,
    });
  },
  updateFolder(data) {
    return axios({
      method: 'put',
      url: baseUrl + '/folder',
      headers,
      data,
    });
  },
  deleteFolder(id) {
    const params = {
      folderId: id,
    };

    return axios({
      method: 'delete',
      url: `${baseUrl}/folder`,
      headers,
      params,
    });
  },
  updateFolderStatus(id) {
    const params = {
      folderId: id,
    };

    return axios({
      method: 'put',
      url: `${baseUrl}/folder/status`,
      headers,
      params,
    });
  },
  getFoldersOrder() {
    return axios({
      method: 'get',
      url: `${baseUrl}/folders/order`,
      headers,
    });
  },
  updateFoldersOrder(order) {
    const data = {
      reorders: order, // DEV NOTE: will be modified to send only the array itself
    };

    return axios({
      method: 'put',
      url: `${baseUrl}/folders/reorder`,
      headers,
      data,
    });
  },
};
