import axios from 'axios';

const baseUrl = import.meta.env.VITE_API_GENERAL;
const headers = {
  'Content-Type': 'application/json; Charset=UTF-8',
};

export default {
  getCouponBatches(options = null) {
    let params = null;

    if (options) {
      const {
        country = '',
        couponPartner = '',
        subType = '',
        couponType = '',
        duration = '',
        fromDate = '',
        toDate = '',
        offset = 0,
        limit = 10,
        keyword = '',
        type = '',
        sort = '',
        searchType = ''
      } = options;

      params = {
        offset,
        limit, // DEV Note: should be fetchLimit
        product: subType,
        type: couponType,
        country,
        partner: couponPartner,
        duration,
        fromDate,
        toDate,
        search: keyword,
        searchType
      };

      if (sort) {
        params.sortColumns = type;
        params.sortColumnOrders = sort;
      }
    }

    return axios({
      method: 'get',
      url: `${baseUrl}/report/coupons`,
      headers,
      params,
    });
  },
  getCouponBatchDetails(options = null) {
    let params = null;
    if (options) {
      const {
        couponStatus = '',
        couponId = 0,
        country = '',
        offset = 0,
        limit = 10,
        keyword = '',
        type = '',
        sort = '',
      } = options;

      params = {
        status: couponStatus,
        couponId,
        country,
        offset,
        limit, // DEV Note: should be fetchLimit
        search: keyword,
      };

      if (sort) {
        params.sortColumns = type;
        params.sortColumnOrders = sort;
      }
    }

    return axios({
      method: 'get',
      url: `${baseUrl}/report/coupons`,
      headers,
      params,
    });
  },
  getBatchDetails(id) {
    const params = {
      id,
    };

    return axios({
      method: 'get',
      url: `${baseUrl}/report/coupon-batch/details`,
      headers,
      params,
    });
  },
  downloadCouponBatchFile(id, extension) {
    const params = {
      couponId: id,
    };

    return axios({
      method: 'get',
      url: `${baseUrl}/report/coupons/${extension}`,
      headers,
      params,
    });
  },
  deleteCouponBatches(id) {
    const params = {
      id,
    };

    return axios({
      method: 'delete',
      url: `${baseUrl}/report/coupons`,
      headers,
      params,
    });
  },
  createCoupon(data) {
    // DEV NOTE: temporary fix, will be depricated
    const { typeId, productId } = data;
    let typeIdVal;
    let productIdVal;
    // eslint-disable-next-line default-case
    switch (typeId) {
      case 'STANDARD_COUPON':
        typeIdVal = 1;
        break;
      case 'FREE_ACCESS_COUPON':
        typeIdVal = 2;
        break;
      case 'UNIQUE_COUPON':
        typeIdVal = 3;
        break;
      case 'UNIQUE_ACCESS_COUPON':
        typeIdVal = 4;
        break;
      case 'DISCOUNT_COUPON':
        typeIdVal = 5;
        break;
      case 'UNIQUE_DISCOUNT_COUPON':
        typeIdVal = 6;
        break;
    }

    // eslint-disable-next-line default-case
    switch (productId) {
      case 'KIDJO_TV':
        productIdVal = 1;
        break;
      case 'KIDJO_BOOKS':
        productIdVal = 2;
        break;
      case 'KIDJO_GAMES':
        productIdVal = 4;
        break;
      case 'KIDJO_TV_BOOKS':
        productIdVal = 3;
        break;
      case 'KIDJO_TV_GAMES':
        productIdVal = 5;
        break;
      case 'KIDJO_BOOKS_GAMES':
        productIdVal = 6;
        break;
      case 'KIDJO_TV_BOOKS_GAMES':
        productIdVal = 7;
        break;
    }

    data.typeId = typeIdVal;
    data.productId = productIdVal;
    // DEV NOTE: end temporary fix block

    return axios({
      method: 'post',
      url: `${baseUrl}/coupon`,
      headers,
      data,
    });
  },
  getPartners() {
    return axios({
      method: 'get',
      url: `${baseUrl}/partners`,
      headers,
    });
  },
  createPartner(data) {
    return axios({
      method: 'post',
      url: baseUrl + '/partner',
      headers,
      data,
    });
  },
  getUserCountries() {
    return axios({
      method: 'get',
      url: `${import.meta.env.VITE_API_GENERAL}/report/users/countries`,
      headers,
    });
  },
  getCouponTypes() {
    const config = {
      method: 'get',
      url: `${import.meta.env.VITE_API_GENERAL}/coupon/types`,
      headers
    };
    return axios(config);
  }
};
