<template>
  <div class="position-relative">
    <div class="wizard wizard-3">
      <tab-nav
        v-if="hasTabs"
        :items="tabs" :active-id="activeTabId"
        @tabClick="onTabSwitch"
      />

      <div class="row py-12 px-8 py-lg-15 px-lg-10">
        <div class="col-md-12 col-xl-12 col-xxl-12">
          <slot :active-tab-id="activeTabId" />
        </div>
      </div>
    </div>

    <editor-overlay v-if="isLoading" />
  </div>
</template>

<script>
  import { isNonEmptyArr } from '@/core/utils';
  import TabNav from '@/components/editor/Header';
  import EditorOverlay from '@/components/editor/Overlay';

  export default {
    name: 'FormEditor',
    components: { TabNav, EditorOverlay },
    props: {
      tabs: {
        type: Array,
        default: () => [],
      },
      isLoading: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        activeTabId: 0,
      };
    },
    computed: {
      hasTabs() {
        return isNonEmptyArr(this.tabs);
      }
    },
    methods: {
      onTabSwitch(id) {
        this.activeTabId = id;
      },
    },
  };
</script>
