export default [
  {
    path: '/coupons',
    name: 'coupons-index',
    role: ['admin', 'developer', 'customer_success_agent'],
    meta: {
      loadState: true,
      requiresAuth: true,
      isAuthView: false,
      parentGroup: 'general',
    },
    component: () => import('@/views/general/coupons/Index'),
  },
  {
    path: '/coupons/details/:batchId/:searchType?',
    name: 'batch-details',
    role: ['admin', 'developer', 'customer_success_agent'],
    meta: {
      loadState: true,
      requiresAuth: true,
      isAuthView: false,
      parentGroup: 'general',
    },
    component: () => import('@/views/general/coupons/Details'),
    props: true,
  },
  {
    path: '/coupons/create',
    name: 'coupons-create',
    role: ['admin', 'developer', 'customer_success_agent'],
    meta: {
      loadState: true,
      requiresAuth: true,
      isAuthView: false,
      parentGroup: 'general',
    },
    component: () => import('@/views/general/coupons/Create'),
  }
];
