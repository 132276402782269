export default [
  {
    path: '/tv/statistics',
    name: 'tv-statistics-index',
    role: ['admin', 'developer'],
    meta: {
      loadState: true,
      requiresAuth: true,
      isAuthView: false,
      parentGroup: 'tv',
    },
    component: () => import('@/views/tv/statistics/Index'),
  },
];
