export default [
  {
    path: '/',
    component: () => import('@/views/basic/Login'),
    children: [
      {
        name: 'login',
        path: '/login',
        meta: {
          loadState: false,
          requiresAuth: false,
          isAuthView: true,
        },
        component: () => import('@/views/basic/Login'),
      },
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404',
  },
  {
    path: '/404',
    name: '404',
    meta: {
      loadState: false,
      requiresAuth: false,
      isAuthView: false,
    },
    component: () => import('@/views/basic/NotFound'),
  },
];
