export const indexColumns = [
  {
    type: 'license',
    value: 'license',
    label: 'License',
    isSortable: true,
  },
  {
    type: 'title',
    value: 'title',
    label: 'Title',
    isSortable: false,
  },
  {
    type: 'folder',
    value: 'folder',
    label: 'Folders',
    isSortable: false,
  },
  {
    type: 'languages',
    value: 'languages',
    label: 'Language',
    isSortable: false,
  },
  {
    type: 'isPremium',
    value: 'isPremium',
    label: 'Premium',
    isSortable: false,
  },
  {
    type: 'views',
    value: 'views',
    label: 'Views',
    isSortable: false,
  },
  {
    type: 'offline',
    value: 'offline',
    label: 'Offline',
    isSortable: false,
  },
];

// mock data
export const folders = [
  {
    id: 1,
    slug: 'folder_mock_1',
    label: 'Folder Mock Data 1',
  },
  {
    id: 2,
    slug: 'folder_mock_2',
    label: 'Folder Mock Data 2',
  },
];

// mock data
export const owners = [
  {
    id: 1,
    slug: 'owner_mock_1',
    label: 'Owner Mock Data 1',
  },
  {
    id: 2,
    slug: 'owner_mock_2',
    label: 'Owner Mock Data 2',
  },
];

// mock data
export const age = [
  {
    id: 'age_2',
    slug: 'age_2',
    label: '2',
  },
  {
    id: 'age_3',
    slug: 'age_3',
    label: '3',
  },
  {
    id: 'age_4',
    slug: 'age_4',
    label: '4',
  },
  {
    id: 'age_5',
    slug: 'age_5',
    label: '5',
  },
  {
    id: 'age_6',
    slug: 'age_6',
    label: '6',
  },
  {
    id: 'age_7',
    slug: 'age_7',
    label: '7',
  },
];

const sharedConfig = {
  clearable: true,
  variant: 'outlined',
  hideDetails: 'auto',
};

export const filterConfig = [
  {
    name: 'dateRange',
    controlType: 'dateRange',
    placeholder: 'Date',
    teleport: true,
    range: true,
    enableTimePicker: false,
    closeOnAutoApply: true,
    autoApply: true,
    ...sharedConfig
  },
  {
    name: 'owners',
    controlType: 'autocomplete',
    label: 'Owner',
    itemTitle: 'label',
    itemValue: 'id',
    items: owners,
    ...sharedConfig,
    hideDetails: false
  },
  {
    name: 'license',
    controlType: 'autocomplete',
    label: 'License',
    itemTitle: 'title',
    itemValue: 'id',
    items: [],
    ...sharedConfig,
    hideDetails: false
  },
  {
    name: 'plan',
    controlType: 'autocomplete',
    label: 'Premium',
    itemTitle: 'label',
    itemValue: 'id',
    items: [],
    ...sharedConfig,
    hideDetails: false
  },
  {
    name: 'folder',
    controlType: 'autocomplete',
    label: 'Folder',
    itemTitle: 'label',
    itemValue: 'id',
    items: folders,
    ...sharedConfig,
    hideDetails: false
  },
  {
    name: 'age',
    controlType: 'autocomplete',
    label: 'Age',
    itemTitle: 'label',
    itemValue: 'id',
    items: age,
    ...sharedConfig,
    hideDetails: false
  },
  {
    name: 'country',
    controlType: 'autocomplete',
    label: 'Country',
    itemTitle: 'name',
    itemValue: 'name',
    items: [],
    ...sharedConfig,
    hideDetails: false
  },
  {
    name: 'language',
    controlType: 'autocomplete',
    label: 'Language',
    itemTitle: 'label',
    itemValue: 'id',
    items: [],
    ...sharedConfig,
    hideDetails: false
  },
  {
    name: 'thematic',
    controlType: 'autocomplete',
    label: 'Thematic',
    itemTitle: 'label',
    itemValue: 'id',
    items: [],
    ...sharedConfig,
    hideDetails: false
  },
];

export default { indexColumns, filterConfig };
