import TvOwners from '@/core/services/tv.owners';
import TvLicenses from '@/core/services/tv.licenses';
import TvFolders from '@/core/services/tv.folders';
import TvVideos from '@/core/services/tv.videos';
import StoriesOwners from '@/core/services/stories.owners';
import StoriesLicenses from '@/core/services/stories.licenses';
import StoriesFolders from '@/core/services/stories.folders';
import StoriesBooks from '@/core/services/stories.books';
import GeneralCoupons from '@/core/services/general.coupons';
import GeneralUsers from '@/core/services/general.users';

export default {
  tv: {
    ...TvOwners,
    ...TvLicenses,
    ...TvFolders,
    ...TvVideos,
  },
  stories: {
    ...StoriesOwners,
    ...StoriesLicenses,
    ...StoriesFolders,
    ...StoriesBooks,
  },
  general: {
    ...GeneralUsers,
    ...GeneralCoupons,
  },
};
