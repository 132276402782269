import Services from '@/core/services/';
import { uid } from 'uid';

export default {
  methods: {
    setFile({ id, type, file, meta }, queue) {
     queue.push({ id, type, file, meta });
    },
    removeFile(id, queue) {
      delete queue[id];
    },
    getFileQueueData(queue, objectId, serviceKey, url = null) { // DEV NOTE: second parameter will be depricated, it's used to determing which service will be used as meta
      const formData = new FormData();
      queue.forEach(element => {
        if (element.type === 'partnerCover') {
          formData.append(`cover_${element.id}`, element.file);
          formData.append(`cover_${element.id}_meta_partner`, JSON.stringify(element.meta));
        } else {
          formData.append(`${element.type}_${element.id}`, element.file);
          formData.append(`${element.type}_${element.id}_meta`, JSON.stringify(element.meta));
        }
      });
      if (serviceKey === 'tv') {
        formData.append('coverId', queue[0].coverId || null);
      }
      const folderId = !objectId ? uid() : objectId;
      if (url) {
        return Services[serviceKey].createBookImage(formData, folderId);
      }
      return Services[serviceKey].createFolderImage(formData, folderId);
    },
  },
};
