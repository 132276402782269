import axios from 'axios';

const baseUrl = import.meta.env.VITE_API_TV;
const headers = {
  'Content-Type': 'application/json; Charset=UTF-8',
};
const imageHeaders = {
  'Content-Type': 'multipart/form-data',
};

export default {
  getVideos(options = null) {
    let params = null;

    if (options) {
      const {
        activity = '',
        language = '',
        license = '',
        owners = '',
        folders = '',
        offset = 0,
        limit = 10,
        keyword = '',
        type = '',
        sort = '',
        premium = undefined,
        dialogue = undefined
      } = options;

      params = {
        offset,
        fetchLimit: limit,
        licenseId: license,
        languageId: language,
        ownerId: owners,
        folderId: folders,
        isActive: activity,
        search: keyword,
      };

      if (premium) {
        params.premium = premium;
      }

      if (dialogue) {
        params.dialogue = dialogue;
      }

      if (sort) {
        params.sortColumns = type;
        params.sortColumnOrders = sort;
      }
    }

    return axios({
      method: 'get',
      url: `${baseUrl}/videos`,
      headers,
      params,
    });
  },
  getFTPVideosCount(videoIncomingId = null) {
    return axios({
      method: 'get',
      url: `${baseUrl}/videos/incoming/ftp/count`,
      headers,
      params: {
        videoIncomingId,
      },
    });
  },
  getUploadedVideos(options = null) {
    let params = null;

    if (options) {
      const {
        offset = 0,
        limit = 10,
        premium = undefined,
        dialogue = undefined,
        status = ''
      } = options;

      params = {
        offset,
        fetchLimit: limit,
        status
      };
      if (premium) {
        params.premium = premium;
      }

      if (dialogue) {
        params.dialogue = dialogue;
      }
      // if (sort) {
      //   // params.sortColumns = type;
      //   // params.sortColumnOrders = sort;
      // }
    }

    return axios({
      method: 'get',
      url: `${baseUrl}/videos/incoming`,
      headers,
      params,
    });
  },
  getUploadedVideoById(videoIncomingId = null) {
    return axios({
      method: 'get',
      url: `${baseUrl}/videos/incoming/edit`,
      headers,
      params: {
        videoIncomingId,
      },
    });
  },
  imageMetaHandler(data, id) {
    return axios({
      method: 'put',
      url: `${baseUrl}/s3/video/thumbnailImage?videoId=${id}`,
      imageHeaders,
      data
    });
  },
  getVideoDetails(id) {
    const params = {
      videoId: id,
    };

    return axios({
      method: 'get',
      url: `${baseUrl}/video`,
      headers,
      params,
    });
  },
  updateVideo(data) {
    return axios({
      method: 'put',
      url: `${baseUrl}/video`,
      headers,
      data,
    });
  },
  uploadFilesVideos(licenseId, data) {
    return axios({
      method: 'post',
      url: `${baseUrl}/videos/incoming/local`,
      imageHeaders,
      params: { licenseId },
      data,
    });
  },
  uploadYouTubeVideos(data) {
    return axios({
      method: 'post',
      url: `${baseUrl}/videos/incoming/youtube`,
      headers,
      data,
    });
  },
  uploadScanFtpVideos(licenseId) {
    return axios({
      method: 'post',
      url: `${baseUrl}/videos/incoming/ftp/scan`,
      headers,
      params: { licenseId },
    });
  },
  updateIncomingVideo(data, incomingVideoId) {
    return axios({
      method: 'post',
      url: `${baseUrl}/video`,
      headers,
      data,
      params: { incomingVideoId },
    });
  },
  deleteVideo(id) {
    const params = {
      videoId: id,
    };

    return axios({
      method: 'delete',
      url: `${baseUrl}/video`,
      headers,
      params,
    });
  },
  deleteVideoLang(videoLanguageId) {
    return axios({
      method: 'delete',
      url: `${baseUrl}/video/language`,
      headers,
      params: {
        videoLanguageId,
      }
    });
  },
  changeVideoStatus(id, status) {
    const params = {
      videoId: id,
      isActive: status,
    };

    return axios({
      method: 'put',
      url: `${baseUrl}/video/status`,
      headers,
      params,
    });
  },
  changeVideoPremiumStatus(id, status) {
    const params = {
      videoId: id,
      isPremium: status,
    };

    return axios({
      method: 'put',
      url: `${baseUrl}/video/status`,
      headers,
      params,
    });
  },
};
