export const indexColumns = [
  {
    type: 'title',
    value: 'title',
    label: 'Title',
    isSortable: true,
  },
  {
    type: 'languageIds',
    value: 'languageIds',
    label: 'Languages',
    isSortable: true,
  },
  {
    type: 'numOfBooks',
    value: 'numOfBooks',
    label: 'Number of book(s)',
    isSortable: true,
  },
  {
    type: 'isActive',
    value: null,
    label: 'Active',
    isSortable: true,
  },
  {
    type: 'actions',
    value: null,
    label: 'Actions',
    isSortable: false,
  },
];

export const orderingColumns = [
  {
    type: 'drag',
    value: null,
    label: 'Reorder',
    isSortable: false,
  },
  {
    type: 'order',
    value: null,
    label: 'Order',
    isSortable: false,
  },
  {
    type: 'title',
    value: null,
    label: 'Title',
    isSortable: false,
  },
  {
    type: 'numOfItems',
    value: null,
    label: 'Number of book(s)',
    isSortable: false,
  },
  {
    type: 'cover',
    value: null,
    label: 'Cover image',
    isSortable: false,
  },
];

export const editorTabs = [
  {
    id: 0,
    slug: 'title',
    label: 'Title',
  },
  {
    id: 1,
    slug: 'content',
    label: 'Content',
  },
];

const sharedConfig = {
  clearable: true,
  variant: 'outlined',
  // DEV NOTE: must be 'false' for 'v-autocomplete'
  hideDetails: true,
};

export const filterConfig = [
  {
    name: 'keyword',
    controlType: 'text',
    label: 'Search',
    prependInnerIcon: 'mdi-magnify',
    ...sharedConfig,
  },
  {
    // DEV NOTE: due to bug in 'autocmplete' temporary use 'select'
    name: 'license',
    controlType: 'autocomplete',
    label: 'License',
    itemTitle: 'title',
    itemValue: 'id',
    items: [],
    ...sharedConfig,
    hideDetails: false
  },
  {
    name: 'language',
    controlType: 'autocomplete',
    label: 'Language',
    itemTitle: 'label',
    itemValue: 'id',
    items: [],
    ...sharedConfig,
    hideDetails: false
  },
  {
    name: 'activity',
    controlType: 'autocomplete',
    label: 'Status',
    itemTitle: 'label',
    itemValue: 'id',
    items: [],
    ...sharedConfig,
    hideDetails: false
  },
];

export const filterConfigOrdering = [
  {
    // DEV NOTE: due to bug in 'autocmplete' temporary use 'autocomplete'
    name: 'country',
    controlType: 'autocomplete',
    label: 'Countries',
    itemTitle: 'label',
    itemValue: 'id',
    items: [],
    ...sharedConfig,
    hideDetails: true
  },
  {
    name: 'thematic',
    controlType: 'autocomplete',
    label: 'Thematics',
    itemTitle: 'label',
    itemValue: 'id',
    items: [],
    ...sharedConfig,
    hideDetails: true,
  },
  {
    name: 'language',
    controlType: 'autocomplete',
    label: 'Languages',
    itemTitle: 'label',
    itemValue: 'id',
    items: [],
    ...sharedConfig,
    hideDetails: true,
  },
];

export default { indexColumns, orderingColumns, editorTabs, filterConfig, filterConfigOrdering };
