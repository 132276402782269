<template>
  <div v-if="hasResults" class="example-preview">
    <div class="d-flex justify-content-between align-items-center flex-wrap">
      <div class="d-flex flex-wrap py-2 mr-3">
        <button
          class="btn btn-icon btn-sm btn-default mr-2 my-1"
          :class="generateTestClass('first-page-action')"
          @click="goToFirst()"
        >
          <i class="mdi mdi-chevron-double-left" />
        </button>
        <button
          class="btn btn-icon btn-sm btn-default mr-2 my-1"
          :class="generateTestClass('page-down-action')"
          @click="goPageDown()"
        >
          <i class="mdi mdi-chevron-left" />
        </button>

        <div v-if="selectedPage > 6">
          <button
            class="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1"
            :class="generateTestClass('page-one-action')"
            @click="goToPage(1)"
          >
            1
          </button>
        </div>
        <div v-if="selectedPage > 7">
          <button
            class="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1"
            :class="generateTestClass('show-more-action')"
          >
            ...
          </button>
        </div>
        <div v-for="(page, index) in pages" :key="index">
          <button
            class="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1"
            @click="goToPage(page)"
            :class="[selectedPage == page ? 'active' : '', generateTestClass('exact-page-action', index) ]"
            v-if="page >= (selectedPage - 5) && page <= (selectedPage + 5)"
          >
            {{ page }}
          </button>
        </div>
        <div v-if="6 < (pages - selectedPage)">
          <button
            class="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1"
            :class="generateTestClass('show-more-action')"
          >
            ...
          </button>
        </div>
        <div v-if="5 < (pages - selectedPage)">
          <button
            class="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1"
            :class="generateTestClass('pages-action')"
            @click="goToPage(pages)"
          >
            {{ pages }}
          </button>
        </div>

        <button
          class="btn btn-icon btn-sm btn-default mr-2 my-1"
          :class="generateTestClass('page-up-action')"
          @click="goPageUp()"
        >
          <i class="mdi mdi-chevron-right" />
        </button>
        <button
          class="btn btn-icon btn-sm btn-default mr-2 my-1"
          :class="generateTestClass('last-page-action')"
          @click="goToLast()"
        >
          <i class="mdi mdi-chevron-double-right" />
        </button>
      </div>
      <div class="d-flex align-items-center py-3">
        <div
          v-if="parentLoading"
          class="d-flex align-items-center"
          :class="generateTestClass('pages-loading')"
        >
          <div class="mr-2 text-muted">Loading...</div>
          <div class="spinner spinner-primary mr-10"></div>
        </div>
        <select
          class="form-control form-control-sm text-default font-weight-bold mr-4 border-0 bg-light"
          :class="generateTestClass('pages-select-control')"
          style="width: 75px;"
          @change="changePerPage($event)"
        >
          <option value="10">10</option>
          <option value="15">15</option>
          <option value="20">20</option>
          <option value="30">30</option>
        </select>
        <span class="text-muted">Showing {{ showing(parseInt(offset), parseInt(perPage)) }} - {{ size }} records</span>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['total', 'parentLoading', 'selectPage'],
    data() {
      return {
        pages: 0,
        perPage: 10,
        size: 0,
      };
    },
    computed: {
      hasResults() {
        return this.total > 0;
      },
      selectedPage: {
        get() {
          return this.$store.getters['breadcrumbs/page'];
        },
        set(val) {
          this.$store.dispatch('breadcrumbs/setPaginationPage', val);
        }
      },
      offset: {
        get() {
          return this.$store.getters['breadcrumbs/offset'];
        },
        set(val) {
          this.$store.dispatch('breadcrumbs/setPaginationOffset', val);
        }
      }
    },
    methods: {
      showing(offset, perPage) {
        // If total is not yet updated
        // eslint-disable-next-line eqeqeq
        if (this.total == 0) {
          return this.size;
        }
        // if we have less results then per Page
        // eslint-disable-next-line eqeqeq
        if (this.selectedPage == 1 && this.total < this.perPage) {
          return this.total;
        }

        if (this.total < (offset + perPage)) {
          return this.total;
        }

        return offset + perPage;
      },
      goToFirst() {
        if (!this.parentLoading) {
          this.selectedPage = 1;
          this.offset = 0;
          this.refresh();
        }
      },
      goToPage(page) {
        if (!this.parentLoading) {
          this.selectedPage = page;
          this.offset = this.calculateOffset(page);
          this.refresh();
        }
      },
      goToLast() {
        if (!this.parentLoading) {
          this.selectedPage = this.pages;
          this.offset = this.calculateOffset(this.pages);
          this.refresh();
        }
      },
      goPageDown() {
        if (!this.parentLoading) {
          if (this.selectedPage > 1) {
            // eslint-disable-next-line no-plusplus
            this.selectedPage--;
            this.offset = this.calculateOffset(this.selectedPage);
            this.refresh();
          }
        }
      },
      goPageUp() {
        if (!this.parentLoading) {
          if (this.selectedPage < this.pages) {
            // eslint-disable-next-line no-plusplus
            this.selectedPage++;
            this.offset = this.calculateOffset(this.selectedPage);
            this.refresh();
          }
        }
      },
      calculateOffset(page) {
        return ((page - 1) * this.perPage);
      },
      calculatePages(listSize) {
        const pages = listSize / this.perPage;
        this.size = listSize;

        if (Number.isInteger(pages)) {
          this.pages = pages;
        } else {
          this.pages = (Math.trunc(pages) + 1);
        }

        const beyondLastPage = this.selectedPage > this.pages;
        if (this.selectPage && this.offset < this.calculateOffset(this.selectPage)) {
          this.selectedPage = this.selectPage;
          this.offset = this.calculateOffset(this.selectPage);
          // this.refresh();
        } else if (this.selectedPage < 1) {
          this.selectedPage = 1;
        } else if (beyondLastPage || this.offset < this.calculateOffset(this.selectedPage)) {
          this.selectedPage = beyondLastPage ? this.pages : this.selectedPage;
          this.offset = this.calculateOffset(this.selectedPage);
          // this.refresh();
        }
      },
      changePerPage(event) {
        const prevPerPage = this.perPage;
        this.perPage = event.target.value;

        if (prevPerPage > this.perPage) {
          this.offset = this.calculateOffset(this.selectedPage);
        }
        this.refresh();
      },
      resetPageDefaultSelection() {
        this.$emit('changePageSelection', this.offset, this.perPage);
      },
      refresh() {
        this.$store.dispatch('breadcrumbs/setPaginationPage', this.selectedPage);
        this.$store.dispatch('breadcrumbs/setPaginationOffset', this.offset);
        this.$emit('changePage', this.offset, this.perPage);
      }
    }
  };
</script>
