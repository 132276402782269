import axios from 'axios';
import store from '@/core/store';

/**
 * @description Replace request token
 * @param request
 * @returns {*}
 */
const replaceRequestToken = (request) => {
  const { headers } = request;
  const nextToken = store.getters['auth/token'];

  headers.Authorization = `Bearer ${nextToken}`;

  return {
    ...request,
    headers
  };
};

/**
 * @description Request interceptor
 * @param config
 * @returns {*}
 */
const requestInterceptor = (config) => {
  const augmentedConfig = { ...config };
  const nextConfig = replaceRequestToken(augmentedConfig);

  return nextConfig;
};

// use custom interceptor
axios.interceptors.request.use(
  requestInterceptor,
);
