import initialState from '@/core/store/initialState';
import { isObj, isArr, isNull } from '@/core/utils';

/**
* @description Is valid
* @param coupons
* @returns {boolean}
*/
const isValid = (coupons) => isObj(coupons)
  && isArr(coupons.items)
  && isNull(coupons.size)
  && isObj(coupons.activeItem);

/**
* @description Init state
* @param initialState
* @returns {*}
*/
const initState = (initialState) => {
  if (!isValid(initialState)) {
    throw Error('Invalid initial stories coupons state');
  }

  const { items, size, activeItem, filters, couponTypes } = initialState;
  return {
    items,
    size,
    activeItem,
    filters,
    couponTypes
  };
};

/**
* @description Getters
* @type {*}
*/
export const getters = {
  items: ({ items }) => items,
  size: ({ size }) => size,
  activeItem: ({ activeItem }) => activeItem,
  filters: ({ filters }) => filters,
  couponTypes: ({ couponTypes }) => couponTypes
};

const actions = {
  setFilters: ({ commit }, nextFilters) => {
    commit('FILTER', nextFilters);
  },
  reset: ({ commit }) => (
    commit('SET', initialState.generalCoupons)
  ),
  setCouponTypes: ({ commit, couponType }) => {
    commit('COUPON_TYPES', couponType);
  }
};

const mutations = {
  SET(state, coupons) {
    state.filters = coupons.filters;
    state.items = coupons.items;
    state.activeItem = coupons.activeItem;
    state.size = coupons.size;
  },
  FILTER(state, filters) {
    state.filters = filters;
  },
  COUPON_TYPES(state, couponTypes) {
    state.couponTypes = couponTypes;
  }
};

export default initialState => ({
  namespaced: true,
  state: initState(initialState),
  getters,
  actions,
  mutations,
});
