export default [
  {
    path: '/tv/licenses',
    name: 'tv-licenses-index',
    role: ['admin', 'developer'],
    meta: {
      loadState: true,
      requiresAuth: true,
      isAuthView: false,
      parentGroup: 'tv',
    },
    component: () => import('@/views/tv/licenses/Index'),
  },
  {
    path: '/tv/licenses/create',
    name: 'tv-license-create',
    role: ['admin', 'developer'],
    meta: {
      loadState: true,
      requiresAuth: true,
      isAuthView: false,
      parentGroup: 'tv',
    },
    component: () => import('@/views/tv/licenses/Create'),
  },
  {
    path: '/tv/licenses/edit/:id',
    name: 'tv-license-edit',
    role: ['admin', 'developer'],
    meta: {
      loadState: true,
      requiresAuth: true,
      isAuthView: false,
      parentGroup: 'tv',
    },
    component: () => import('@/views/tv/licenses/Edit'),
  },
  {
    path: '/tv/licenses/order',
    name: 'tv-licenses-order',
    role: ['admin', 'developer'],
    meta: {
      loadState: true,
      requiresAuth: true,
      isAuthView: false,
      parentGroup: 'tv',
    },
    component: () => import('@/views/tv/licenses/Order'),
  },
];
