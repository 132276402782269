export default [
  {
    path: '/tv/videos',
    name: 'tv-videos-index',
    role: ['admin', 'developer'],
    meta: {
      loadState: true,
      requiresAuth: true,
      isAuthView: false,
      parentGroup: 'tv',
    },
    component: () => import('@/views/tv/videos/Index'),
  },
  {
    path: '/tv/videos/create',
    name: 'tv-videos-create',
    role: ['admin', 'developer'],
    meta: {
      loadState: true,
      requiresAuth: true,
      isAuthView: false,
      parentGroup: 'tv',
    },
    component: () => import('@/views/tv/videos/Create'),
  },
  {
    path: '/tv/upload/video/edit/:id',
    name: 'tv-upload-video-edit',
    role: ['admin', 'developer'],
    meta: {
      loadState: true,
      requiresAuth: true,
      isAuthView: false,
      parentGroup: 'tv',
    },
    component: () => import('@/views/tv/videos/Edit'),
  },
  {
    path: '/tv/videos/edit/:id',
    name: 'tv-videos-edit',
    role: ['admin', 'developer'],
    meta: {
      loadState: true,
      requiresAuth: true,
      isAuthView: false,
      parentGroup: 'tv',
    },
    component: () => import('@/views/tv/videos/Edit'),
  },
  {
    path: '/tv/videos/upload',
    name: 'tv-videos-upload',
    role: ['admin', 'developer'],
    meta: {
      loadState: true,
      requiresAuth: true,
      isAuthView: false,
      parentGroup: 'tv',
    },
    component: () => import('@/views/tv/videos/Upload'),
  },
];
