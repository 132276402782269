export default [
  {
    path: '/stories/books',
    name: 'stories-books-index',
    role: ['admin', 'developer'],
    meta: {
      loadState: true,
      requiresAuth: true,
      isAuthView: false,
      parentGroup: 'stories',
    },
    component: () => import('@/views/stories/books/Index'),
  },
  {
    path: '/stories/books/create',
    name: 'stories-book-create',
    role: ['admin', 'developer'],
    meta: {
      loadState: true,
      requiresAuth: true,
      isAuthView: false,
      parentGroup: 'stories',
    },
    component: () => import('@/views/stories/books/Create'),
  },
  {
    path: '/stories/books/edit/:id',
    name: 'stories-book-edit',
    role: ['admin', 'developer'],
    meta: {
      loadState: true,
      requiresAuth: true,
      isAuthView: false,
      parentGroup: 'stories',
    },
    component: () => import('@/views/stories/books/Edit'),
    props: true,
  },
  {
    path: '/stories/books/edit/:id/pages/:bundle',
    name: 'stories-book-pages-edit',
    role: ['admin', 'developer'],
    meta: {
      loadState: true,
      requiresAuth: true,
      isAuthView: false,
      parentGroup: 'stories',
    },
    component: () => import('@/views/stories/books/Pages'),
    props: true,
  },
];
