<template>
  <div
    class="subheader py-2 py-lg-4 subheader-solid solid bg-white border-top"
    id="kt_subheader"
  >
    <div class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap container-fluid">
      <div class="d-flex align-items-center flex-wrap mr-1">
        <slot name="pre-title" />
        <h5 class="text-dark font-weight-bold my-2 mr-5">
          {{ title }}
        </h5>
        <ul
          class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2"
        >
          <li class="breadcrumb-item">
            <router-link :to="'/dashboard'" class="subheader-breadcrumbs-home">
              <i class="mdi mdi-home text-muted icon-1x" />
            </router-link>
          </li>

          <template v-for="(breadcrumb, i) in breadcrumbs" :key="`${i}-${breadcrumb.id}`">
            <li class="breadcrumb-item">
              <router-link
                v-if="breadcrumb.route"
                :key="i"
                :to="breadcrumb.route"
                class="text-muted"
              >
                {{ breadcrumb.title }}
              </router-link>
              <span
                class="text-muted"
                :key="i"
                v-if="!breadcrumb.route"
              >
                {{ breadcrumb.title }}
              </span>
            </li>
          </template>
        </ul>
      </div>
      <div>
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Subheader',
    props: {
      breadcrumbs: Array,
      title: String
    },
  };
</script>
