<template>
  <div
    id="kt_header_mobile"
    class="header-mobile align-items-center header-mobile-fixed"
  >
    <a href="/">
      <img
        src="/logo.png" alt="Kidjo Logo"
        class="header-mobile__logo"
      />
    </a>

    <div class="d-flex align-items-center">
      <button
        id="kt_aside_mobile_toggle"
        class="btn p-0"
      >
        <i class="mdi mdi-menu" />
      </button>

      <a
        href="#" class="btn btn-hover-text-primary p-0 ml-2"
        @click.prevent="onLogout"
      >
        <i class="mdi mdi-logout" />
      </a>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'HeaderMobile',
    methods: {
      onLogout() {
        this.$store.dispatch('auth/logout')
          .then(() => this.$router.push({ name: 'login' }))
          .catch(err => err);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .header-mobile {
    &__logo {
      max-height: 30px;
    }
  }
</style>
