<template>
  <ul class="menu-nav">
    <template v-for="(group, groupIndex) in navList">
      <li
        v-if="group.label" :key="groupIndex"
        class="menu-section"
      >
        <h4 class="menu-text">{{ group.label }}</h4>
        <i class="menu-icon mdi mdi-dots-horizontal" />
      </li>
      <li
        v-for="tab in group.items" :key="tab.slug"
        :class="[
          'menu-item',
          { 'menu-item-submenu': tab.items },
          { 'menu-item-active': !tab.items && tab.slug === selectedMainMenu },
          { 'menu-item-open': tab.items && tab.slug === selectedMainMenu },
        ]"
      >
        <a
          :class="[
            'menu-link',
            { 'menu-toggle': tab.items },
          ]"
          @click.prevent="handleMenuItemClick(tab)"
        >
          <i :class="['menu-icon', 'mdi', `mdi-${tab.icon}`]" />
          <span class="menu-text">{{ tab.label }}</span>
          <i v-if="tab.items" class="dropdown-arrow mdi mdi-chevron-right" />
        </a>

        <div
          v-if="tab.items"
          class="menu-submenu"
        >
          <ul class="menu-subnav">
            <li
              v-for="(menuItem, index) in tab.items" :key="menuItem.slug"
              aria-haspopup="true" data-menu-toggle="hover"
              :class="[
                'menu-item',
                { 'menu-item-active': menuItem.slug === selectedSubMenu },
              ]"
            >
              <a
                class="menu-link"
                @click.prevent="handleMenuItemClick(tab, index)"
              >
                <i class="menu-bullet mdi mdi-circle-small" />
                <span class="menu-text">{{ menuItem.label }}</span>
              </a>
            </li>
          </ul>
        </div>
      </li>
    </template>
  </ul>
</template>

<script>
  import { isStr, isNull, isNonEmptyArr } from '@/core/utils';
  import filterMenu from '@/mixins/filterMenu';

  export default {
    name: 'Menu',
    mixins: [filterMenu],
    data() {
      return {
        activeTab: null,
        activeGroup: null,
        activeGroupTab: null,
      };
    },
    computed: {
      currentRoute() {
        return this.$route.name;
      },
      selectedSubMenu: {
        get() {
          return this.$store.getters['breadcrumbs/menu'];
        },
        set(selection) {
          this.$store.dispatch('breadcrumbs/setSelectedMenu', selection);
        }
      },
      selectedMainMenu: {
        get() {
          return this.$store.getters['breadcrumbs/mainMenu'];
        },
        set(selection) {
          this.$store.dispatch('breadcrumbs/setSelectedMainMenu', selection);
        }
      },
      getUserRoles() {
        return this.$store.getters['auth/userRoles'];
      },
      navList() {
        const menu = this.$store.getters['auth/filteredMenu'];
        return menu.length ? menu : this.filteredMainMenu();
      },
    },
    methods: {
      handleMenuItemClick({ route = null, slug, items = [] }, index = null) {
        if (isNonEmptyArr(items) && isNull(index)) {
          this.activeGroup = this.activeGroup !== slug ? slug : null;
          this.selectedMainMenu = this.activeGroup;
        } else {
          let nextRoute = null;

          this.activeTab = null;
          this.activeGroupTab = null;
          this.selectedSubMenu = this.activeGroupTab;

          if (isNull(index)) {
            nextRoute = route;
            this.activeTab = slug;
            this.selectedMainMenu = this.activeTab;
          } else {
            const { route, slug } = items[index];

            nextRoute = route;
            this.activeGroupTab = slug;
            this.selectedSubMenu = this.activeGroupTab;
          }

          if (!isNull(this.activeTab)) {
            this.activeGroup = null;
            this.$store.dispatch('breadcrumbs/setSelectedMenu', null);
          }

          if (isStr(nextRoute) && nextRoute !== this.currentRoute) {
            this.$store.dispatch('breadcrumbs/setPaginationPage', 1);
            this.$store.dispatch('breadcrumbs/setPaginationOffset', 0);
            this.$router.push({ name: nextRoute });
          }
        }
      },
    },
    mounted() {
      if (this.navList.length > 0) {
        // this.$router.push({ name: this.$store.getters['auth/permissions'][0] });
      }
    }
  };
</script>

<style lang="scss">
  .menu-item {
    .dropdown-arrow {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 18px;
      font-size: 1.30rem;
      color: #494b74;
      transition: transform .2s ease-in-out;
    }

    &.menu-item-open .dropdown-arrow {
      color: #ffffff;
      transform: rotate(90deg);

    }

    .menu-bullet {
      color: #494b74;
    }

    &.menu-item-active .menu-bullet {
      color: #3699FF;
    }

    .menu-submenu {
      display: block !important;
      max-height: 0;
      opacity: 0;
      overflow: hidden;
      transition: max-height .1s ease-out, opacity .1s ease-out;
    }

    &.menu-item-open .menu-submenu {
      display: block;
      max-height: 100vh;
      opacity: 1;
      transition: max-height .4s ease-in, opacity .4s ease-in;
    }
  }
</style>
