export const indexColumns = [
  {
    type: 'title',
    value: 'title',
    label: 'Title',
    isSortable: true,
  },
  {
    type: 'cover',
    value: null,
    label: 'Cover image',
    isSortable: false,
  },
  {
    type: 'folder_category', // DEV NOTE: request change on this
    value: 'folderCategory',
    label: 'Thematic',
    isSortable: true,
  },
  {
    type: 'languageIds',
    value: 'languageIds',
    label: 'Language',
    isSortable: true,
  },
  {
    type: 'folder',
    value: 'folderTitle',
    label: 'Folders',
    isSortable: true,
  },
  {
    type: 'is_active', // DEV NOTE: request change on this
    value: null,
    label: 'Active',
    isSortable: true,
  },
  {
    type: 'is_premium', // DEV NOTE: request change on this
    value: null,
    label: 'Premium',
    isSortable: true,
  },
  {
    type: 'actions',
    value: null,
    label: 'Actions',
    isSortable: false,
  },
];

export const editorTabs = [
  {
    id: 0,
    slug: 'attributes',
    label: 'Attributes',
  },
  {
    id: 1,
    slug: 'languages',
    label: 'Languages',
  },
];

const sharedConfig = {
  clearable: true,
  variant: 'outlined',
  // DEV NOTE: must be 'false' for 'v-autocomplete'
  hideDetails: true,
};

export const filterConfig = [
  {
    name: 'keyword',
    controlType: 'text',
    label: 'Search',
    prependInnerIcon: 'mdi-magnify',
    ...sharedConfig,
  },
  {
    // DEV NOTE: due to bug in 'autocmplete' temporary use 'select'
    name: 'license',
    controlType: 'autocomplete',
    label: 'License',
    itemTitle: 'title',
    itemValue: 'id',
    items: [],
    ...sharedConfig,
    hideDetails: false
  },
  {
    name: 'thematic',
    controlType: 'autocomplete',
    label: 'Thematic',
    itemTitle: 'label',
    itemValue: 'id',
    items: [],
    ...sharedConfig,
    hideDetails: false
  },
  {
    name: 'language',
    controlType: 'autocomplete',
    label: 'Language',
    itemTitle: 'label',
    itemValue: 'id',
    items: [],
    ...sharedConfig,
    hideDetails: false
  },
  {
    name: 'activity',
    controlType: 'autocomplete',
    label: 'Status',
    itemTitle: 'label',
    itemValue: 'id',
    items: [],
    ...sharedConfig,
    hideDetails: false

  },
];

export default { indexColumns, editorTabs, filterConfig };
