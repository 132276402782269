<template>
  <div class="wizard-nav border-bottom mb-1 mb-lg-5">
    <div class="wizard-steps px-8 py-8 px-lg-15 py-lg-3">
      <div
        v-for="(item, index) in items"
        :key="index"
        :class="[
          'wizard-step',
          { 'wizard-step--active': item.id === activeId },
        ]"
      >
        <div
          class="wizard-label"
          @click="onItemClick(item.id)"
        >
          <h3 class="wizard-title" :class="generateTestClass(item.label)">
            <span>{{ index + 1 }}</span> {{ item.label }}
          </h3>
          <div class="wizard-bar"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      items: {
        type: Array,
        required: true,
      },
      activeId: {
        type: Number,
        default: null,
      },
    },
    methods: {
      onItemClick(id) {
        this.$emit('tabClick', id);
      },
    },
  };
</script>

<style lang="scss">
  .wizard-step {
    padding: 2rem 0.5rem;

    &--active {
      .wizard-label {
        color: #3699FF !important;
      }

      .wizard-bar {
        background: #3699FF !important;
      }
    }

    .wizard-label {
      padding: 0 !important;

      &:hover {
        cursor: pointer;

        .wizard-bar {
          background: red;
        }
      }
    }
  }
</style>
