<template>
  <div
    class="aside aside-left aside-fixed d-flex flex-column flex-row-auto"
    @mouseover="mouseEnter"
    @mouseleave="mouseLeave"
  >
    <div class="brand flex-column-auto">
      <div class="brand-logo">
        <router-link to="/dashboard">
          <img
            src="/logo.png" alt="Kidjo Logo"
          />
        </router-link>
      </div>
      <div class="brand-tools">
        <a
          class="brand-toggle btn btn-sm px-0"
          @click.prevent="toggleSidebar"
        >
          <i class="mdi mdi-chevron-double-left" />
        </a>
      </div>
    </div>

    <div class="aside-menu-wrapper flex-column-fluid">
      <div class="aside-menu aside-fixed my-4">
        <div class="aside-menu scroll" style="position: relative; max-height: 90vh; overflow: auto;">
          <Menu />
        </div>

        <div class="aside_footer">
          <span class="font-weight-bold mr-1">
            {{ new Date().getFullYear() }} &nbsp;&copy;&nbsp;
          </span>
          Kidjo
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Menu from '@/components/base/Menu';

  export default {
    name: 'SideBar',
    components: { Menu },
    data() {
      return {
        isSidebarMinimized: false,
        insideTm: 0,
        outsideTm: 0,
      };
    },
    methods: {
      toggleSidebar() {
        this.isSidebarMinimized = !this.isSidebarMinimized;

        if (this.isSidebarMinimized) {
          document.body.classList.add('aside-minimize');
        } else {
          document.body.classList.remove('aside-minimize-hover');
        }
      },
      mouseEnter() {
        if (document.body.classList.contains('aside-fixed')) {
          if (this.outsideTm) {
            clearTimeout(this.outsideTm);
            this.outsideTm = null;
          }

          if (document.body.classList.contains('aside-minimize')) {
            document.body.classList.add('aside-minimize-hover');
            document.body.classList.remove('aside-minimize');
          }
        }
      },
      mouseLeave() {
        if (document.body.classList.contains('aside-fixed')) {
          if (this.insideTm) {
            clearTimeout(this.insideTm);
            this.insideTm = null;
          }

          if (document.querySelector('.aside-menu .scroll')) {
            document.querySelector('.aside-menu .scroll').scrollTop = 0;
          }

          if (document.body.classList.contains('aside-minimize-hover')) {
            document.body.classList.remove('aside-minimize-hover');
            document.body.classList.add('aside-minimize');
          }
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .brand-logo img {
    max-height: 42px;
  }

  .brand-toggle {
    i {
      font-size: 1.8em;
    }

    .aside-minimize & i {
      transform: rotate(180deg);
    }
  }

  .aside-menu {
    scrollbar-width: none;

    ::-webkit-scrollbar {
      display: none;
    }
  }

  .aside-toggle {
    outline: none;
  }

  .aside_footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 8px 12px;
    font-size: 10px;
    color: #4c4e6f;
  }
</style>
