import initialState from '@/core/store/initialState';
import { isObj, isArr, isNull } from '@/core/utils';

/**
* @description Is valid
* @param owners
* @returns {boolean}
*/
const isValid = (owners) => isObj(owners)
  && isNull(owners.draft)
  && isNull(owners.filters)
  && isArr(owners.items)
  && isNull(owners.size)
  && isObj(owners.activeItem);

/**
* @description Init state
* @param initialState
* @returns {*}
*/
const initState = (initialState) => {
  if (!isValid(initialState)) {
    throw Error('Invalid initial tv owners state');
  }

  const { items, size, activeItem, draft, filters } = initialState;
  return {
    items,
    size,
    activeItem,
    draft,
    filters,
  };
};

/**
* @description Getters
* @type {*}
*/
export const getters = {
  items: ({ items }) => items,
  size: ({ size }) => size,
  activeItem: ({ activeItem }) => activeItem,
  draft: ({ draft }) => draft,
  filters: ({ filters }) => filters,
};

const actions = {
  setDraft: ({ commit }, nextDraft) => {
    commit('DRAFT', nextDraft);
  },
  resetDraft: ({ commit }) => {
    commit('DRAFT', null);
  },
  setFilters: ({ commit }, nextFilters) => {
    commit('FILTER', nextFilters);
  },
  reset: ({ commit }) => (
    commit('SET', initialState.tvOwners)
  ),
  set: ({ commit }, owners) => {
    commit('SET', owners);
  }
};

const mutations = {
  SET(state, owners) {
    state.token = owners.token;
    state.user = owners.user;
    state.role = owners.role;
  },
  DRAFT(state, draft) {
    state.draft = draft;
  },
  FILTER(state, filters) {
    state.filters = filters;
  },
};

export default initialState => ({
  namespaced: true,
  state: initState(initialState),
  getters,
  actions,
  mutations,
});
