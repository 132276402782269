import axios from 'axios';

const baseUrl = import.meta.env.VITE_API_GENERAL;
const headers = {
  'Content-Type': 'application/json; Charset=UTF-8',
};

export default {
  getUsers(options = null) {
    let params = null;

    if (options) {
      const {
        countryCode = '',
        subStatus = '',
        subMethod = '',
        promoContent = '',
        subType = '',
        couponType = '',
        couponPartner = '',
        dateRange = [],
        offset = 0,
        limit = 10,
        keyword = '',
        type = '',
        sort = '',
      } = options;

      const [fromDate, toDate] = dateRange || [];

      params = {
        limit,
        offset,
        subMethod,
        promoContent,
        partnerId: couponPartner,
        subType,
        couponType,
        subStatus,
        countryCode,
        fromDate,
        toDate,
        search: keyword,
      };

      if (sort) {
        params.sortColumns = type;
        params.sortColumnOrders = sort;
      }
    }

    return axios({
      method: 'get',
      url: `${baseUrl}/report/accounts`,
      headers,
      params,
    });
  },
  getFilteredUsers(options = null) {
    let params = null;

    if (options) {
      const {
        countryCode = '',
        subStatus = '',
        subMethod = '',
        promoContent = '',
        subType = '',
        couponType = '',
        couponPartner = 0,
        dateRange = [],
      } = options;

      const [fromDate, toDate] = dateRange || [];

      params = {
        fromDate,
        toDate,
        countryCode,
        subMethod,
        subStatus,
        couponType,
        productType: subType,
        partnerId: couponPartner,
        promoContent
      };
    }
    return axios({
      method: 'get',
      url: `${baseUrl}/report/filter/accounts`,
      headers,
      params,
    });
  },
  getUser(id) {
    const params = {
      userId: id,
    };

    return axios({
      method: 'get',
      url: `${baseUrl}/report/user`,
      headers,
      params,
    });
  },
  getAccount(id) {
    const params = {
      accountId: id,
    };

    return axios({
      method: 'get',
      url: `${baseUrl}/report/account`,
      headers,
      params,
    });
  },
  getUserCountries() {
    return axios({
      method: 'get',
      url: `${import.meta.env.VITE_API_GENERAL}/report/users/countries`,
      headers,
    });
  },
  downloadUsers(options = null) {
    let params = null;

    if (options) {
      const {
        countryCode = '',
        subStatus = '',
        subMethod = '',
        promoContent = '',
        subType = '',
        couponType = '',
        couponPartner = '',
        dateRange = [],
        offset = 0,
        limit = 10,
        keyword = '',
        type = '',
        sort = '',
      } = options;

      const [fromDate, toDate] = dateRange || [];

      params = {
        subMethod,
        promoContent,
        partnerId: couponPartner,
        subType,
        couponType,
        subStatus,
        countryCode,
        fromDate,
        toDate,
        search: keyword,
      };

      if (sort) {
        params.sortColumns = type;
        params.sortColumnOrders = sort;
      }
    }
    return axios({
      method: 'get',
      url: `${baseUrl}/report/users/csv`,
      headers,
      params,
    });
  }
};
