<template>
  <div class="row">
    <div class="col-md-12 overflow">
      <table
        role="table"
        aria-busy="false"
        aria-colcount="3"
        class="table b-table table-hover"
        :class="generateTestClass(tableName)"
      >
        <thead role="rowgroup" class="thead-light">
          <tr role="row">
            <th
              v-if="canDragItems"
              role="columnheader"
              scope="col"
            >
              Reorder
            </th>
            <th
              role="columnheader"
              scope="col"
              v-for="(column, index) in columns"
              :key="index"
              :class="getTestClass(column.label, index)"
            >
              <slot
                v-if="hasCustomColumnTemplate"
                name="item-column-cell"
                :cell="{
                  id: column.type,
                  value: column.value,
                  label: column.label,
                }"
              />

              <template v-else>
                {{ column.label }}
              </template>
            </th>
          </tr>
        </thead>

        <tbody role="rowgroup" class="tbody-bordered">
          <tr
            role="row"
            v-for="(item, index) in items"
            :key="index"
          >
            <td
              v-if="canDragItems"
              aria-colindex="0"
              role="cell"
              class="align-middle text-center"
            >
              <button
                type="button"
                class="btn btn-rounded btn-secondary mr-2 btn-icon btn-sm handle"
                @click.prevent
              >
                <i class="mdi mdi-drag-horizontal-variant" />
              </button>
            </td>
            <td
              v-for="(column, columnIndex) in columns"
              :key="column.type"
              class="align-middle"
              :class="getTestClass(item[column.value], columnIndex)"
            >
              <slot
                v-if="hasCustomItemsTemplate"
                :cell="{
                  id: column.type,
                  value: column.value,
                  item,
                  rowIndex: index,
                }"
                name="item-cell"
              />

              <template v-else>
                {{ item[column.value] }}
              </template>
            </td>
          </tr>
          <tr v-if="!hasItems">
            <td colspan="6" style="text-align: center;">{{ isLoading ? 'Loading...' : 'No Results' }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import { isNonEmptyArr, isStr, isNum } from '@/core/utils';

  export default {
    name: 'SimpleTable',
    props: {
      tableName: {
        type: String,
        default: '',
      },
      items: {
        type: Array,
        default: () => [],
      },
      columns: {
        type: Array,
        default: () => [],
      },
      isLoading: {
        type: Boolean,
        default: false,
      },
      hasCustomColumnTemplate: {
        type: Boolean,
        default: false,
      },
      hasCustomItemsTemplate: {
        type: Boolean,
        default: false,
      },
      canDragItems: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      hasItems() {
        return isNonEmptyArr(this.items);
      },
    },
    methods: {
      getTestClass(text, index) {
        let className = 'actions';

        if (isStr(text)) {
          className = text.split(' ').join('-').toLowerCase();
        }

        if (isNum(text)) {
          className = text.toString();
        }

        return this.generateTestClass(className, index);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .overflow {
    overflow-x: auto
  }

  .tbody-bordered td {
    border: 1px solid #EBEDF3;
  }
</style>
