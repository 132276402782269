export default {
  locale: {
    language: 'en'
  },
  config: {
    serviceKey: null,
    roles: [],
    regions: [],
    countries: [],
    thematics: [],
    languages: [],
    rightsType: [],
    countryRegionList: [],
  },
  auth: {
    token: null,
    user: {},
    userRoles: [],
    permissions: [],
    filteredMenu: [],
    redirects: {
      index: '/',
      unauthenticated: '/login',
      authenticated: '/dashboard'
    }
  },
  tvOwners: {
    draft: null,
    filters: null,
    items: [],
    activeItem: {},
    size: null,
  },
  tvLicenses: {
    draft: null,
    filters: null,
    items: [],
    activeItem: {},
    size: null,
  },
  tvFolders: {
    draft: null,
    filters: null,
    items: [],
    activeItem: {},
    size: null,
  },
  tvVideos: {
    draft: null,
    filters: null,
    items: [],
    activeItem: {},
    size: null,
  },
  storiesOwners: {
    draft: null,
    filters: null,
    items: [],
    activeItem: {},
    size: null,
  },
  storiesLicenses: {
    draft: null,
    filters: null,
    items: [],
    activeItem: {},
    size: null,
    licenses: []
  },
  storiesFolders: {
    draft: null,
    filters: null,
    items: [],
    activeItem: {},
    size: null,
  },
  storiesBooks: {
    draft: null,
    filters: null,
    items: [],
    activeItem: {},
    size: null,
  },
  generalUsers: {
    filters: null,
    items: [],
    activeItem: {},
    size: null,
  },
  generalCoupons: {
    filters: {
      searchType: 'BATCH_NAME',
    },
    items: [],
    activeItem: {},
    size: null,
    couponTypes: [],
  }
};
