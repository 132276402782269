export const indexColumns = [
  {
    type: 'groupId',
    value: 'groupId',
    label: 'Batch name',
    isSortable: true,
  },
  {
    type: 'startDate',
    value: 'startDate',
    label: 'Creation',
    isSortable: true,
  },
  {
    type: 'type',
    value: 'type',
    label: 'Coupon type',
    isSortable: true,
  },
  {
    type: 'product',
    value: 'product',
    label: 'Product',
    isSortable: true,
  },
  {
    type: 'duration',
    value: 'durationCode',
    label: 'Duration',
    isSortable: false,
  },
  {
    type: 'expireDate',
    value: 'expireDate',
    label: 'Expiration',
    isSortable: true,
  },
  {
    type: 'partner',
    value: 'partner',
    label: 'Partner',
    isSortable: true,
  },
  {
    type: 'redeemed',
    value: 'redeemed',
    label: 'Redeemed',
    isSortable: false,
  },
  {
    type: 'actions',
    value: null,
    label: 'Actions',
    isSortable: false,
  },
];

export const batchColumns = [
  {
    type: 'serialNumber',
    value: 'serialNumber',
    label: 'Code',
  },
  {
    type: 'email',
    value: 'email',
    label: 'Email',
  },
  {
    type: 'status',
    value: 'status',
    label: 'Coupon status',
  },
  {
    type: 'product',
    value: 'product',
    label: 'Product',
  },
  {
    type: 'country',
    value: 'country',
    label: 'Country',
  },
  { // DEV NOTE: only available on 'standart' type coupon variations
    type: 'couponType',
    value: 'type',
    label: 'Type',
  },
  {
    type: 'startDate',
    value: 'startDate',
    label: 'Coupon activation',
  },
  {
    type: 'actions',
    value: null,
    label: '',
  },
];

const sharedConfig = {
  clearable: true,
  variant: 'outlined',
  // DEV NOTE: must be 'false' for 'v-autocomplete'
  hideDetails: true,
};

export const filterConfig = [
  {
    name: 'keyword',
    controlType: 'text',
    label: 'Search',
    prependInnerIcon: 'mdi-magnify',
    ...sharedConfig
  },
  {
    name: 'searchType',
    controlType: 'autocomplete',
    label: 'Search Filter',
    itemTitle: 'label',
    itemValue: 'id',
    items: [],
    ...sharedConfig,
    clearable: false,
    hideDetails: false
  },
  {
    name: 'subType',
    controlType: 'autocomplete',
    label: 'Product',
    itemTitle: 'label',
    itemValue: 'id',
    items: [],
    ...sharedConfig,
    hideDetails: false
  },
  {
    name: 'couponType',
    controlType: 'autocomplete',
    label: 'Coupon type',
    itemTitle: 'label',
    itemValue: 'id',
    items: [],
    ...sharedConfig,
    hideDetails: false
  },
  {
    name: 'duration',
    controlType: 'select',
    label: 'Duration',
    itemTitle: 'label',
    itemValue: 'id',
    items: [],
    ...sharedConfig
  },
  {
    name: 'dateRange',
    controlType: 'dateRange',
    placeholder: 'Creation date',
    teleport: true,
    range: true,
    enableTimePicker: false,
    closeOnAutoApply: true,
    autoApply: true,
    ...sharedConfig
  },
  {
    // DEV NOTE: due to bug in 'autocmplete' temporary use 'select'
    name: 'country',
    controlType: 'autocomplete',
    label: 'Country',
    itemTitle: 'name',
    itemValue: 'name',
    items: [],
    ...sharedConfig,
    hideDetails: false
  },
  {
    // DEV NOTE: due to bug in 'autocmplete' temporary use 'select'
    name: 'couponPartner',
    controlType: 'autocomplete',
    label: 'Partner',
    itemTitle: 'name',
    itemValue: 'name',
    items: [],
    ...sharedConfig,
    hideDetails: false
  },
];

export const filterConfigDetails = [
  {
    name: 'keyword',
    controlType: 'text',
    label: 'Search',
    prependInnerIcon: 'mdi-magnify',
    ...sharedConfig
  },
  {
    name: 'couponStatus',
    controlType: 'select',
    label: 'Coupon status',
    itemTitle: 'label',
    itemValue: 'id',
    items: [],
    ...sharedConfig
  },
  {
    name: 'country',
    controlType: 'select',
    label: 'Country',
    itemTitle: 'name',
    itemValue: 'name',
    items: [],
    ...sharedConfig
  },
];

export default { indexColumns, batchColumns, filterConfig, filterConfigDetails };
