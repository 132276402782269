import initialState from '@/core/store/initialState';
import { isObj, isArr, isNull } from '@/core/utils';

/**
* @description Is valid
* @param licenses
* @returns {boolean}
*/
const isValid = (licenses) => isObj(licenses)
  && isNull(licenses.draft)
  && isNull(licenses.filters)
  && isArr(licenses.items)
  && isNull(licenses.size)
  && isObj(licenses.activeItem);

/**
* @description Init state
* @param initialState
* @returns {*}
*/
const initState = (initialState) => {
  if (!isValid(initialState)) {
    throw Error('Invalid initial tv licenses state');
  }

  const { items, size, activeItem, draft, filters } = initialState;
  return {
    items,
    size,
    activeItem,
    draft,
    filters,
  };
};

/**
* @description Getters
* @type {*}
*/
export const getters = {
  items: ({ items }) => items,
  size: ({ size }) => size,
  activeItem: ({ activeItem }) => activeItem,
  draft: ({ draft }) => draft,
  filters: ({ filters }) => filters,
};

const actions = {
  setDraft: ({ commit }, nextDraft) => {
    commit('DRAFT', nextDraft);
  },
  resetDraft: ({ commit }) => {
    commit('DRAFT', null);
  },
  setFilters: ({ commit }, nextFilters) => {
    commit('FILTER', nextFilters);
  },
  reset: ({ commit }) => (
    commit('SET', initialState.tvLicenses)
  ),
  set: ({ commit }, licenses) => {
    commit('SET', licenses);
  },
};

const mutations = {
  SET(state, licenses) {
    state.token = licenses.token;
    state.user = licenses.user;
    state.role = licenses.role;
  },
  DRAFT(state, draft) {
    state.draft = draft;
  },
  FILTER(state, filters) {
    state.filters = filters;
  },
};

export default initialState => ({
  namespaced: true,
  state: initState(initialState),
  getters,
  actions,
  mutations,
});
