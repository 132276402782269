export default [
  {
    path: '/tv/owners',
    name: 'tv-owners-index',
    role: ['admin', 'developer'],
    meta: {
      loadState: true,
      requiresAuth: true,
      isAuthView: false,
      parentGroup: 'tv',
    },
    component: () => import('@/views/tv/owners/Index'),
  },
  {
    path: '/tv/owners/create',
    name: 'tv-owner-create',
    role: ['admin', 'developer'],
    meta: {
      loadState: true,
      requiresAuth: true,
      isAuthView: false,
      parentGroup: 'tv',
    },
    component: () => import('@/views/tv/owners/Create'),
  },
  {
    path: '/tv/owners/edit/:id',
    name: 'tv-owner-edit',
    role: ['admin', 'developer'],
    meta: {
      loadState: true,
      requiresAuth: true,
      isAuthView: false,
      parentGroup: 'tv',
    },
    component: () => import('@/views/tv/owners/Edit'),
  },
];
