import { createStore } from 'vuex';
import persistentStore from '@/core/store/persistentStore';
import initialState from '@/core/store/initialState';

// eslint-disable-next-line import/no-cycle
import auth from '@/core/store/modules/basic.auth';
import breadcrumbs from '@/core/store/modules/basic.breadcrumbs';
// eslint-disable-next-line import/no-cycle
import config from '@/core/store/modules/basic.config';
import storiesOwners from '@/core/store/modules/stories.owners';
import storiesLicenses from '@/core/store/modules/stories.licenses';
import storiesFolders from '@/core/store/modules/stories.folders';
import storiesBooks from '@/core/store/modules/stories.books';
import tvOwners from '@/core/store/modules/tv.owners';
import tvLicenses from '@/core/store/modules/tv.licenses';
import tvFolders from '@/core/store/modules/tv.folders';
import tvVideos from '@/core/store/modules/tv.videos';
import generalUsers from '@/core/store/modules/general.users';
import generalCoupons from '@/core/store/modules/general.coupons';

const store = createStore({
  plugins: [persistentStore.plugin],
  modules: {
    auth: auth(initialState.auth),
    breadcrumbs,
    config: config(initialState.config),
    tvOwners: tvOwners(initialState.tvOwners),
    tvLicenses: tvLicenses(initialState.tvLicenses),
    tvFolders: tvFolders(initialState.tvFolders),
    tvVideos: tvVideos(initialState.tvVideos),
    storiesOwners: storiesOwners(initialState.storiesOwners),
    storiesLicenses: storiesLicenses(initialState.storiesLicenses),
    storiesFolders: storiesFolders(initialState.storiesFolders),
    storiesBooks: storiesBooks(initialState.storiesBooks),
    generalUsers: generalUsers(initialState.generalUsers),
    generalCoupons: generalCoupons(initialState.generalCoupons)
  },
});

/**
 * @description Reset stores
 * @param store
 */
export const resetStores = (store) => {
  // store.dispatch('auth/reset');
  store.dispatch('tvOwners/reset');
  store.dispatch('tvLicenses/reset');
  store.dispatch('tvFolders/reset');
  store.dispatch('tvVideos/reset');
  store.dispatch('storiesOwners/reset');
  store.dispatch('storiesLicenses/reset');
  store.dispatch('storiesFolders/reset');
  store.dispatch('storiesBooks/reset');
  store.dispatch('generalUsers/reset');
  store.dispatch('generalCoupons/reset');
};

export default store;
