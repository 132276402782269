export default [
  {
    path: '/stories/statistics',
    name: 'stories-statistics-index',
    role: ['admin', 'developer'],
    meta: {
      loadState: true,
      requiresAuth: true,
      isAuthView: false,
      parentGroup: 'stories',
    },
    component: () => import('@/views/stories/statistics/Index'),
  },
];
