<template>
  <div class="row">
    <div class="col-md-12 overflow">
      <table
        role="table"
        aria-busy="false"
        aria-colcount="3"
        class="table b-table table-hover"
        :class="generateTestClass(tableName)"
      >
        <thead
          v-if="hasItems"
          role="rowgroup" class="thead-light"
        >
          <tr role="row">
            <th
              role="columnheader"
              scope="col"
              v-for="(column, index) in columns"
              :key="index"
              :class="[
                {
                  sortable: column.isSortable,
                  'sort--asc': activeFilter.type === column.type && activeFilter.sort === 'ascending',
                  'sort--desc': activeFilter.type === column.type && activeFilter.sort === 'descending',
                },
                getTestClass(column.label, index),
                getTestClass(column.label)
              ]"
              @click="sortBy(column)"
            >
              {{ column.label }}
              <div v-if="column.isSortable" class="sortable__indicator">
                <i
                  class="mdi mdi-chevron-up sortable__indicator--asc"
                  :class="generateTestClass('column-asc-action')"
                />
                <i
                  class="mdi mdi-chevron-down sortable__indicator--desc"
                  :class="generateTestClass('column-desc-action')"
                />
              </div>
            </th>
          </tr>
        </thead>

        <tbody role="rowgroup" class="tbody-bordered">
          <tr
            role="row"
            v-for="(item, index) in items"
            :key="index"
          >
            <td
              v-for="column in columns"
              :key="column.type"
              class="align-middle"
              :class="[getTestClass(column.label, index), getTestClass(column.label)]"
            >
              <slot
                v-if="hasCustomItemsTemplate"
                :cell="{
                  id: column.type,
                  value: column.value,
                  item,
                  index,
                }"
                name="item-cell"
              />

              <template v-else>
                {{ item[column.value] }}
              </template>
            </td>
          </tr>
          <tr v-if="!hasItems">
            <td colspan="6" style="text-align: center;">{{ isLoading ? 'Loading...' : 'No Results' }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import { isNonEmptyArr, isStr, isNum } from '@/core/utils';

  export default {
    name: 'DataTable',
    props: {
      tableName: {
        type: String,
        default: '',
      },
      items: {
        type: Array,
        default: () => [],
      },
      columns: {
        type: Array,
        default: () => [],
      },
      isLoading: {
        type: Boolean,
        default: false,
      },
      hasCustomItemsTemplate: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        activeFilter: {
          type: null,
          sortFilter: false,
          sort: false,
        },
      };
    },
    computed: {
      hasItems() {
        return isNonEmptyArr(this.items);
      },
    },
    methods: {
      sortBy(column) {
        const { activeFilter } = this;
        const { type, isSortable } = column;

        if (!isSortable || this.isLoading) {
          return false;
        }

        if (activeFilter.type !== type) {
          this.activeFilter = { type, sortFilter: false, sort: false };
        }

        switch (this.activeFilter.sortFilter) {
          case 'ASC':
            this.activeFilter.sortFilter = 'DESC';
            this.activeFilter.sort = 'descending';
            break;
          case 'DESC':
            this.activeFilter.sortFilter = false;
            this.activeFilter.sort = false;
            break;
          default:
            this.activeFilter.sortFilter = 'ASC';
            this.activeFilter.sort = 'ascending';
        }

        this.$emit('sort', this.activeFilter);
      },
      getTestClass(text, index) {
        let className = 'actions';

        if (isStr(text)) {
          className = text.split(' ').join('-').toLowerCase();
        }

        if (isNum(text)) {
          className = text.toString();
        }

        return this.generateTestClass(className, index);
      }
    },
  };
</script>

<style lang="scss" scoped>
  .overflow {
    overflow-x: auto
  }

  .tbody-bordered td {
    border: 1px solid #EBEDF3;
  }

  .sortable {
    position: relative;
    cursor: pointer;
    padding-right: 32px;

    &__indicator {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 10px;
      height: 36px;
      margin: auto;

      i {
        height: 12px;
      }

      .sort--asc &--asc {
        color: #333;
      }

      .sort--desc &--desc {
        color: #333;
      }
    }
  }
</style>
