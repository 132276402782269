import { hasObjField, capitalize } from '@/core/utils';

export default (key) => ({
  computed: {
    lastFilters() {
      if (!this.storeKey) {
        return undefined;
      }

      return this.$store.getters[`${this.storeKey}/filters`];
    },
    serviceKey() {
      return this.$store.getters['config/serviceKey'];
    },
    storeKey() {
      if (!key) {
        return undefined;
      }

      return `${this.serviceKey}${capitalize(key)}`;
    }
  },
  methods: {
    storeFilters(nextFilters) {
      if (!this.storeKey) {
        return;
      }

      this.$store.dispatch(`${this.storeKey}/setFilters`, nextFilters);
    },
    updateExplicitValue(arr, name, key, nextValue) {
      arr.find(config => config.name === name)[key] = nextValue;
    },
    handleChange({ name, value }) {
      this.filter[name] = value;
      this.storeFilters(this.filter);

      if (this.onFilter && name !== 'keyword') {
        this.onFilter(this.filter);
      }
    },
    handleClear({ name, value }) {
      this.filter[name] = value;
      this.storeFilters(this.filter);

      if (this.onFilter) {
        this.onFilter(this.filter);
      }

      this.$emit('onClear', this.filter);
    },
    handlePrependInnerClick() {
      if (this.onFilter) {
        this.onFilter(this.filter);
      }

      this.$emit('onPrependInnerClick', this.filter);
    },
    handleEnter() {
      if (this.onFilter) {
        this.onFilter(this.filter);
      }

      this.$emit('onEnter', this.filter);
    },
    populateOptions() {
      this.configList.forEach((configItem => {
        const itemName = configItem.name.toString();

        if (hasObjField(this.options, itemName)) {
          this.updateExplicitValue(this.configList, itemName, 'items', this.options[itemName]);
        }
      }));
    },
    setDefaultValues() {
      this.configList.forEach((configItem => {
        const itemName = configItem.name;
        const value = this.lastFilters?.[itemName];

        this.filter[itemName] = value;
      }));

      this.storeFilters({ ...this.lastFilters, ...this.filter });
    },
  }
});
