// eslint-disable-next-line import/no-cycle
import store from '@/core/store';
import initialState from '@/core/store/initialState';
import { isObj, isNull, isNonEmptyStr } from '@/core/utils';
import AuthService from '@/core/services/basic.auth';

/**
 * @description Is valid
 * @param auth
 * @param userRoles
 * @returns {boolean}
 */
const isValid = (auth) => isObj(auth)
&& isNull(auth.token);

/**
 * @description Init state
 * @param initialState
 * @returns {*}
 */
const initState = initialState => {
  if (!isValid(initialState)) {
    throw Error('Invalid initial auth state');
  }

  const { token, permissions, userRoles, filteredMenu } = initialState;
  return {
    token,
    userRoles,
    permissions,
    filteredMenu
  };
};

const getters = {
  token: ({ token }) => token,
  userRoles: ({ userRoles }) => userRoles,
  isAuthenticated: ({ token }) => isNonEmptyStr(token),
  permissions: ({ permissions }) => permissions,
  filteredMenu: ({ filteredMenu }) => filteredMenu
};

const actions = {
  login({ commit, state }, auth) {
    return AuthService.login(auth)
      .then(({ data }) => {
        const { token, userRoles } = data;
        const nextAuth = {
          ...state,
          token,
          userRoles
        };

        commit('SET', nextAuth);

        return data;
      });
  },
  logout() {
    store.dispatch('auth/reset').catch((err) => err);
  },
  reset: ({ commit }) => {
    commit('SET', initialState.auth);
  },
  setPermissions: ({ commit }, permissions) => {
    commit('PERMISSIONS', permissions);
  },
  setFilteredMenu: ({ commit }, filteredMenu) => {
    commit('FILTEREDMENU', filteredMenu);
  },
  set: ({ commit }, auth) => {
    commit('SET', auth);
  },
};

const mutations = {
  SET(state, auth) {
    /* eslint-disable no-param-reassign */
    state.token = auth.token;
    state.userRoles = auth.userRoles;
  },
  PERMISSIONS(state, auth) {
    state.permissions = [...auth];
  },
  FILTEREDMENU(state, filteredMenu) {
    state.filteredMenu = [...filteredMenu];
  },
};

export default initialState => ({
  namespaced: true,
  state: initState(initialState),
  getters,
  actions,
  mutations,
});
