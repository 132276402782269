<template>
  <v-dialog v-model="openModel" @click:outside="onDismiss()">
    <v-card>
      <v-card-title>
        <slot name="title" />
      </v-card-title>

      <v-divider />

      <v-card-text>
        <slot name="content" />
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />

        <slot name="actions" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    props: {
      open: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      openModel: {
        get() {
          return this.open;
        },
        set(nextOpen) {
          nextOpen = !nextOpen;
          this.$emit('toggleOpen', nextOpen);
        }
      }
    },
    methods: {
      onDismiss() {
        this.$emit('dismiss');
      },
    },
  };
</script>

<style lang="scss">
  .v-dialog {
    width: 520px !important;

    .v-card {
      .v-card__title {
        padding: 14px 24px !important;
      }

      .v-card__text {
        padding: 20px 24px !important;
      }

      .v-divider {
        margin: 0;
      }
    }
  }
</style>
