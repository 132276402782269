export const mainMenu = [
  {
    id: 1,
    slug: 'nav_group_misc',
    label: null,
    isDisabled: false,
    role: ['admin', 'developer'],
    items: [
      {
        id: 1,
        slug: 'nav_misc_dashboard',
        label: 'Dashboard',
        icon: 'home',
        route: 'dashboard',
        role: ['admin', 'developer'],
      },
    ],
  },
  {
    id: 2,
    slug: 'nav_group_tv',
    label: 'TV',
    isDisabled: false,
    role: ['admin', 'developer'],
    items: [
      {
        id: 1,
        slug: 'nav_tv_owners',
        label: 'Owners',
        icon: 'account',
        isDisabled: false,
        route: 'tv-owners-index',
        role: ['admin', 'developer'],
      },
      {
        id: 2,
        slug: 'nav_tv_licenses',
        label: 'Licenses',
        icon: 'license',
        isDisabled: false,
        role: ['admin', 'developer'],
        items: [
          {
            id: 1,
            slug: 'nav_tv_licenses_list',
            label: 'All Licenses',
            route: 'tv-licenses-index',
            role: ['admin', 'developer'],
          },
          // {
          //   id: 2,
          //   slug: 'nav_tv_licenses_order',
          //   label: 'Deeplinks Ordering',
          //   route: 'tv-licenses-order',
          //   role: ['admin', 'developer'],
          // },
        ]
      },
      {
        id: 3,
        slug: 'nav_tv_folders',
        label: 'Folders',
        icon: 'folder',
        isDisabled: false,
        role: ['admin', 'developer'],
        items: [
          {
            id: 1,
            slug: 'nav_tv_folders_list',
            label: 'All Folders',
            route: 'tv-folders-index',
            role: ['admin', 'developer'],
          },
          {
            id: 2,
            slug: 'nav_tv_folders_order',
            label: 'Folders Ordering',
            route: 'tv-folders-order',
            role: ['admin', 'developer'],
          },
        ],
      },
      {
        id: 4,
        slug: 'nav_tv_videos',
        label: 'Videos',
        icon: 'video',
        isDisabled: false,
        role: ['admin', 'developer'],
        items: [
          {
            id: 1,
            slug: 'nav_tv_video_list',
            label: 'All Videos',
            route: 'tv-videos-index',
            role: ['admin', 'developer'],
          },
          {
            id: 2,
            slug: 'nav_tv_video_upload',
            label: 'Upload(ed) video',
            route: 'tv-videos-upload',
            role: ['admin', 'developer'],
          },
        ],
      },
      {
        id: 5,
        slug: 'nav_tv_statistics',
        label: 'Statistics',
        icon: 'chart-bar',
        isDisabled: true,
        route: 'tv-statistics-index',
        role: ['admin', 'developer'],
      },
    ],
  },
  {
    id: 3,
    slug: 'nav_group_stories',
    label: 'Stories',
    isDisabled: false,
    role: ['admin', 'developer'],
    items: [
      {
        id: 1,
        slug: 'nav_stories_owners',
        label: 'Owners',
        icon: 'account',
        isDisabled: false,
        route: 'stories-owners-index',
        role: ['admin', 'developer'],
      },
      {
        id: 2,
        slug: 'nav_stories_licenses',
        label: 'Licenses',
        icon: 'license',
        isDisabled: false,
        role: ['admin', 'developer'],
        items: [
          {
            id: 1,
            slug: 'nav_stories_licenses_list',
            label: 'All Licenses',
            route: 'stories-licenses-index',
            role: ['admin', 'developer'],
          },
          {
            id: 2,
            slug: 'nav_stories_licenses_order',
            label: 'Deeplinks Ordering',
            route: 'stories-licenses-order',
            role: ['admin', 'developer'],
          },
        ],
      },
      {
        id: 3,
        slug: 'nav_stories_folders',
        label: 'Folders',
        icon: 'folder',
        isDisabled: false,
        role: ['admin', 'developer'],
        items: [
          {
            id: 1,
            slug: 'nav_stories_folders_list',
            label: 'All Folders',
            route: 'stories-folders-index',
            role: ['admin', 'developer'],
          },
          {
            id: 2,
            slug: 'nav_stories_folders_order',
            label: 'Folders Ordering',
            route: 'stories-folders-order',
            role: ['admin', 'developer'],
          },
        ],
      },
      {
        id: 4,
        slug: 'nav_stories_books',
        label: 'Books',
        icon: 'book-open-page-variant',
        isDisabled: false,
        route: 'stories-books-index',
        role: ['admin', 'developer'],
      },
      {
        id: 5,
        slug: 'nav_stories_statistics',
        label: 'Statistics',
        icon: 'chart-bar',
        isDisabled: true,
        route: 'stories-statistics-index',
        role: ['admin', 'developer'],
      },
    ],
  },
  {
    id: 4,
    slug: 'nav_group_general',
    label: 'General',
    isDisabled: false,
    role: ['admin', 'developer', 'customer_success_agent'],
    items: [
      {
        id: 1,
        slug: 'nav_general_users',
        label: 'Users',
        icon: 'account-multiple',
        route: 'users-index',
        role: ['admin', 'developer', 'customer_success_agent'],
      },
      {
        id: 2,
        slug: 'nav_general_coupons',
        label: 'Coupons',
        icon: 'tag-multiple',
        route: 'coupons-index',
        role: ['admin', 'developer', 'customer_success_agent'],
      },
    ],
  },
];

export const ageRange = [
  {
    id: 1,
    slug: 'range_one',
    label: '1 year old',
  },
  {
    id: 2,
    slug: 'range_two',
    label: '2 years old',
  },
  {
    id: 3,
    slug: 'range_three',
    label: '3 years old',
  },
  {
    id: 4,
    slug: 'range_four',
    label: '4 years old',
  },
  {
    id: 5,
    slug: 'range_five',
    label: '5 years old',
  },
  {
    id: 6,
    slug: 'range_six',
    label: '6 years old',
  },
  {
    id: 7,
    slug: 'range_seven',
    label: '7 years old',
  },
  {
    id: 8,
    slug: 'range_eight',
    label: '8 years old',
  },
  {
    id: 9,
    slug: 'range_nine',
    label: '9 years old',
  },
  {
    id: 10,
    slug: 'range_ten',
    label: '10 years old',
  },
];

export const folderTypes = [
  {
    id: 42,
    slug: 'type_folder_entertainment',
    label: 'Cartoon',
  },
  {
    id: 7,
    slug: 'type_folder_educational',
    label: 'Educational',
  },
];

export const languages = [
  {
    id: 82,
    code: 'nl',
    slug: 'lang_dutch',
    label: 'Dutch',
  },
  {
    id: 1,
    code: 'en',
    slug: 'lang_english',
    label: 'English',
  },
  {
    id: 34,
    code: 'fr',
    slug: 'lang_french',
    label: 'French',
  },
  {
    id: 27,
    code: 'es',
    slug: 'lang_spanish',
    label: 'Spanish',
  },
  {
    id: 23,
    code: 'de',
    slug: 'lang_german',
    label: 'German',
  },
  {
    id: 51,
    code: 'it',
    slug: 'lang_italian',
    label: 'Italian',
  },
  {
    id: 94,
    code: 'ru',
    slug: 'lang_russian',
    label: 'Russian',
  },
  {
    id: 89,
    code: 'pt',
    slug: 'lang_protuguese',
    label: 'Portuguese',
  },
  {
    id: 6,
    code: 'ar',
    slug: 'lang_arabic',
    label: 'Arabic',
  },
  // {
  //   id: 0,
  //   code: 'all',
  //   slug: 'lang_all',
  //   label: 'All'
  // }

];

export const activityStatuses = [
  {
    id: true,
    slug: 'activity_active_only',
    label: 'Active only',
  },
  {
    id: false,
    slug: 'activity_inactive_only',
    label: 'Inactive only',
  },
];

export const thematics = [
  {
    id: 1,
    slug: 'thematic_fairy_tale',
    label: 'Fairytales',
    parentGroup: 'stories',
  },
  {
    id: 4,
    slug: 'thematic_adventure',
    label: 'Adventure',
    parentGroup: 'stories',
  },
  {
    id: 5,
    slug: 'thematic_short_stories',
    label: 'Short Stories',
    parentGroup: 'stories',
  },
  {
    id: 6,
    slug: 'thematic_learning',
    label: 'Learning',
    parentGroup: 'stories',
  },
  {
    id: 1,
    slug: 'thematic_еntertainment',
    label: 'Cartoon',
    parentGroup: 'tv',
  },
  {
    id: 2,
    slug: 'thematic_еducation',
    label: 'Education',
    parentGroup: 'tv',
  },
  {
    id: 4,
    slug: 'thematic_music',
    label: 'Music',
    parentGroup: 'tv',
  },
];

export const products = [
  {
    id: 'KIDJO_TV',
    slug: 'product_kidjo_tv',
    label: 'TV',
  },
  {
    id: 'KIDJO_BOOKS',
    slug: 'product_kidjo_stories',
    label: 'Stories',
  },
  {
    id: 'KIDJO_GAMES',
    slug: 'product_kidjo_games',
    label: 'Games',
  },
  {
    id: 'KIDJO_TV_BOOKS',
    slug: 'product_kidjo_tv_stories',
    label: 'TV and Stories',
  },
  {
    id: 'KIDJO_TV_GAMES',
    slug: 'product_kidjo_tv_games',
    label: 'TV and Games',
  },
  {
    id: 'KIDJO_BOOKS_GAMES',
    slug: 'product_kidjo_stories_games',
    label: 'Stories and Games',
  },
  {
    id: 'KIDJO_TV_BOOKS_GAMES',
    slug: 'product_kidjo_tv_stories_games',
    label: 'TV, Stories and Games',
  },

  {
    id: 'None',
    slug: 'product_kidjo_none',
    label: 'None',
  }
];

export const couponTypes = [
  {
    id: 'STANDARD_COUPON',
    slug: 'coupon_plan_payment',
    label: 'Coupon + Plan + Payment',
  },
  {
    id: 'FREE_ACCESS_COUPON',
    slug: 'coupon_access',
    label: 'Coupon + Access',
  },
  {
    id: 'UNIQUE_COUPON',
    slug: 'unique_coupon_plan_payment',
    label: 'Unique Coupon + Plan + Payment',
  },
  {
    id: 'UNIQUE_ACCESS_COUPON',
    slug: 'unique_coupon_access',
    label: 'Unique Coupon + Access',
  },
  {
    id: 'DISCOUNT_COUPON',
    slug: 'discount_coupon',
    label: 'Discount Coupon + Plan + Payment '

  },
  {
    id: 'UNIQUE_DISCOUNT_COUPON',
    slug: 'unique_discount_coupon',
    label: 'Unique Discount Coupon + Plan + Payment '

  },
  {
    id: 'None',
    slug: 'coupon_none',
    label: 'None',
  },
];

export const couponSearchTypes = [
  {
    id: 'BATCH_NAME',
    slug: 'batch_name',
    label: 'Batch name',
  },
  {
    id: 'USER_EMAIL',
    slug: 'user_email',
    label: 'User email',
  },
  {
    id: 'COUPON_CODE',
    slug: 'coupon_code',
    label: 'Coupon code',
  },
];

export const couponDuration = [
  {
    id: 'P1D',
    slug: 'duration_coupon_1d',
    label: '1 day',
  },
  {
    id: 'P7D',
    slug: 'duration_coupon_1w',
    label: '1 week',
  },
  {
    id: 'P14D',
    slug: 'duration_coupon_2w',
    label: '2 weeks',
  },
  {
    id: 'P21D',
    slug: 'duration_coupon_3w',
    label: '3 weeks',
  },
  {
    id: 'P1M',
    slug: 'duration_coupon_1m',
    label: '1 month',
  },
  {
    id: 'P2M',
    slug: 'duration_coupon_2m',
    label: '2 months',
  },
  {
    id: 'P3M',
    slug: 'duration_coupon_3m',
    label: '3 months',
  },
  {
    id: 'P4M',
    slug: 'duration_coupon_4m',
    label: '4 months',
  },
  {
    id: 'P5M',
    slug: 'duration_coupon_5m',
    label: '5 months',
  }, {
    id: 'P6M',
    slug: 'duration_coupon_6m',
    label: '6 months',
  },
  {
    id: 'P7M',
    slug: 'duration_coupon_7m',
    label: '7 months',
  },
  {
    id: 'P8M',
    slug: 'duration_coupon_8m',
    label: '8 months',
  },
  {
    id: 'P9M',
    slug: 'duration_coupon_9m',
    label: '9 months',
  },
  {
    id: 'P10M',
    slug: 'duration_coupon_10m',
    label: '10 months',
  },
  {
    id: 'P11M',
    slug: 'duration_coupon_11m',
    label: '11 months',
  },
  {
    id: 'P12M',
    slug: 'duration_coupon_12m',
    label: '12 months',
  },
  {
    id: 'P24M',
    slug: 'duration_coupon_24m',
    label: '24 months',
  }
];

export const couponStatuses = [
  {
    id: 'PENDING_ACTIVATION',
    slug: 'coupon_status_pending',
    label: 'Pending',
  },
  {
    id: 'ACTIVE',
    slug: 'coupon_status_active',
    label: 'Active',
  },
  {
    id: 'EXPIRED',
    slug: 'coupon_status_expired',
    label: 'Expired',
  },
  {
    id: 'INVALID',
    slug: 'coupon_status_invalid',
    label: 'Invalid',
  },
];

export const subscriptionStatuses = [
  {
    id: 'Active',
    slug: 'subscription_active',
    label: 'Active',
  },
  {
    id: 'Inactive',
    slug: 'subscription_inactive',
    label: 'Inactive',
  },
];

export const plan = [{
  id: 0,
  slug: 'premium',
  label: 'Premium',
},
{
  id: 1,
  slug: 'free',
  label: 'Free',
}];

export const rightsType = [{
  id: 1,
  slug: 'AVOD',
  label: 'AVOD',
},
{
  id: 2,
  slug: 'SVOD',
  label: 'SVOD'
},
{
  id: 3,
  slug: 'AVOD_SVOD',
  label: 'AVOD+SVOD'
}];
export const colors = [
  '#AB1438',
  '#DF1125',
  '#FC4E12',
  '#FB0049',
  '#F12E6D',
  '#FD820B',
  '#FD7441',
  '#CA6F59',
  '#81323D',
  '#383B65',
  '#4662B2',
  '#1871AC',
  '#0F6D8E',
  '#1EA5FC',
  '#5EBAD9',
  '#A990DD',
  '#8962F8',
  '#5F41B2',
  '#757397',
  '#578887',
  '#638A8D',
  '#A97D4F',
  '#788991',
  '#8B7D7D',
  '#7A6E49',
  '#715D50',
];
export default {
  mainMenu,
  ageRange,
  folderTypes,
  languages,
  activityStatuses,
  couponSearchTypes,
  thematics,
  products,
  couponTypes,
  couponDuration,
  plan,
  rightsType,
  colors,
};
