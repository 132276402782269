import axios from 'axios';
import { uid } from 'uid';
// const baseUrl = import.meta.env.VITE_API_BO; // DEV NOTE: this should be a specific configs url
const headers = {
  'Content-Type': 'application/json; Charset=UTF-8',
};
const rolesHeaders = {
  'Content-Type': 'application/json; Charset=UTF-8',
  'transaction-id': uid(),
};
export default {
  getUserRoles() {
    return axios({
      method: 'get',
      url: `${import.meta.env.VITE_API_STORIES}/roles/list`,
      rolesHeaders,
    });
  },
  getAppConfigs() {
    return axios({
      method: 'get',
      url: `${import.meta.env.VITE_API_STORIES}/nomenclatures`, // DEV NOTE: this should be on a different url
      headers,
    });
  },
  // DEV NOTE: this method has been duplicated in the 'general.users' service
  getAccountCountries() {
    return axios({
      method: 'get',
      url: `${import.meta.env.VITE_API_GENERAL}/report/users/countries`, // DEV NOTE: this should be on a different url
      headers,
    });
  },
  getAccountCountriesList() {
    return axios({
      method: 'get',
      url: `${import.meta.env.VITE_API_TV}/country-list`, // DEV NOTE: this should be on a different url
      headers,
    });
  },
  // DEV NOTE: this method has been duplicated in the 'general.users' service
  getSubscriptionMethods() {
    return axios({
      method: 'get',
      url: `${import.meta.env.VITE_API_GENERAL}/stores`, // DEV NOTE: this should be on a different url
      headers,
    });
  },
};
