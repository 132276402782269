export const indexColumns = [
  {
    type: 'createdDate',
    value: 'subStartDate',
    label: 'Start of subscription',
    isSortable: true,
  },
  {
    type: 'name',
    value: 'name',
    label: 'First name',
    isSortable: true,
  },
  {
    type: 'email',
    value: 'email',
    label: 'Email',
    isSortable: true,
  },
  {
    type: 'country',
    value: 'country',
    label: 'Country',
    isSortable: true,
  },
  {
    type: 'subscriptionType',
    value: 'subType',
    label: 'Product',
    isSortable: true,
  },
  {
    type: 'storeId',
    value: 'subMethod',
    label: 'Subscription method',
    isSortable: true,
  },
  {
    type: 'subStatus',
    value: 'subStatus',
    label: 'Subscription status',
    isSortable: true,
  },
  {
    type: 'isPromoAccepted',
    value: 'isPromoAccepted',
    label: 'Promo Accepted',
    isSortable: true,
  },
  {
    type: 'isTcAccepted',
    value: 'isTcAccepted',
    label: 'Tems & conditions Accepted',
    isSortable: true,
  },
  {
    type: 'actions',
    value: null,
    label: 'Actions',
    isSortable: false,
  }
];

const sharedConfig = {
  clearable: true,
  variant: 'outlined',
  // DEV NOTE: must be 'false' for 'v-autocomplete'
  hideDetails: true,
};

export const filterConfig = [
  {
    name: 'keyword',
    controlType: 'text',
    label: 'Search',
    prependInnerIcon: 'mdi-magnify',
    ...sharedConfig
  },
  {
    name: 'subType',
    controlType: 'autocomplete',
    label: 'Product',
    itemTitle: 'label',
    itemValue: 'name',
    items: [],
    ...sharedConfig,
    hideDetails: false
  },
  {
    name: 'couponType',
    controlType: 'autocomplete',
    label: 'Coupon type',
    itemTitle: 'label',
    itemValue: 'id',
    items: [],
    ...sharedConfig,
    hideDetails: false
  },
  {
    name: 'dateRange',
    controlType: 'dateRange',
    placeholder: 'Subscription period',
    teleport: true,
    range: true,
    enableTimePicker: false,
    closeOnAutoApply: true,
    autoApply: true,
    ...sharedConfig
  },
  {
    // DEV NOTE: due to bug in 'autocmplete' temporary use 'select'
    name: 'countryCode',
    controlType: 'autocomplete',
    label: 'Country',
    itemTitle: 'name',
    itemValue: 'code',
    items: [],
    ...sharedConfig,
    hideDetails: false
  },
  {
    name: 'subStatus',
    controlType: 'autocomplete',
    label: 'Subscription status',
    itemTitle: 'label',
    itemValue: 'id',
    items: [],
    ...sharedConfig,
    hideDetails: false
  },
  {
    // DEV NOTE: due to bug in 'autocmplete' temporary use 'select'
    name: 'couponPartner',
    controlType: 'autocomplete',
    label: 'Partner',
    itemTitle: 'name',
    itemValue: 'id',
    items: [],
    ...sharedConfig,
    hideDetails: false
  },
  {
    // DEV NOTE: due to bug in 'autocmplete' temporary use 'select'
    name: 'subMethod',
    controlType: 'autocomplete',
    label: 'Subscription method',
    itemTitle: 'name',
    itemValue: 'id',
    items: [],
    ...sharedConfig,
    hideDetails: false
  },
  {
    // DEV NOTE: due to bug in 'autocmplete' temporary use 'select'
    name: 'promoContent',
    controlType: 'autocomplete',
    label: 'Promotional content',
    itemTitle: 'name',
    itemValue: 'value',
    items: [],
    ...sharedConfig,
    hideDetails: false
  },
];

export default { indexColumns, filterConfig };
