<template>
  <v-app class="app-wrap">
    <router-view v-slot="{ Component }">
      <transition>
        <component :is="Component" />
      </transition>
    </router-view>
  </v-app>
</template>

<script>
  export default {
    name: 'App',
  };
</script>

<style scoped>
  .app-wrap {
    background: #EEF0F8;
  }
</style>

<style>
  /* Vuetify Form Component Reset */
  .v-input input,
  .v-input label,
  .v-select__selection,
  .v-textarea textarea {
    font-size: 13px;
  }
</style>
