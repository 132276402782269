<template>
  <div class="d-flex flex-column flex-root" v-if="isAuthenticated">
    <HeaderMobile />

    <AppLoader logo="/logo.png" />

    <div class="d-flex flex-row flex-column-fluid page">
      <SideBar />

      <div class="d-flex flex-column flex-row-fluid wrapper">
        <AppHeader />

        <div class="content d-flex flex-column flex-column-fluid">
          <Subheader
            v-bind:breadcrumbs="breadcrumbs"
            v-bind:title="pageTitle"
          >
            <template v-slot:pre-title>
              <portal-target name="pre-title" />
            </template>
            <portal-target name="section-actions" />
          </Subheader>

          <div class="d-flex flex-column-fluid">
            <div class="container">
              <transition name="fade-in-up">
                <router-view />
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ScrollTopBtn />
  </div>
</template>

<script>
  import AppHeader from '@/components/base/AppHeader';
  import HeaderMobile from '@/components/base/HeaderMobile';
  import Subheader from '@/components/base/Subheader';
  import SideBar from '@/components/base/SideBar';
  import ScrollTopBtn from '@/components/base/ScrollTopBtn';
  import AppLoader from '@/components/base/AppLoader';

  export default {
    name: 'Layout',
    components: {
      AppHeader,
      HeaderMobile,
      Subheader,
      SideBar,
      ScrollTopBtn,
      AppLoader,
    },
    computed: {
      breadcrumbs() {
        return this.$store.getters['breadcrumbs/breadcrumbs'];
      },
      pageTitle() {
        return this.$store.getters['breadcrumbs/pageTitle'];
      },
      isAuthenticated() {
        return this.$store.getters['auth/isAuthenticated'];
      },
    },
    beforeMount() {
      document.body.classList.add('page-loading');
    },
    mounted() {
      if (!this.isAuthenticated) {
        this.$router.push({ name: 'login' });
      }

      setTimeout(() => {
        document.body.classList.remove('page-loading');
      }, 2000);
    },
  };
</script>
