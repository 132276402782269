export default [
  {
    path: '/tv/folders',
    name: 'tv-folders-index',
    role: ['admin', 'developer'],
    meta: {
      loadState: true,
      requiresAuth: true,
      isAuthView: false,
      parentGroup: 'tv',
    },
    component: () => import('@/views/tv/folders/Index'),
  },
  {
    path: '/tv/folders/create',
    name: 'tv-folder-create',
    role: ['admin', 'developer'],
    meta: {
      loadState: true,
      requiresAuth: true,
      isAuthView: false,
      parentGroup: 'tv',
    },
    component: () => import('@/views/tv/folders/Create'),
  },
  {
    path: '/tv/folders/edit/:id',
    name: 'tv-folder-edit',
    role: ['admin', 'developer'],
    meta: {
      loadState: true,
      requiresAuth: true,
      isAuthView: false,
      parentGroup: 'tv',
    },
    component: () => import('@/views/tv/folders/Edit'),
    props: true,
  },
  {
    path: '/tv/folders/order',
    name: 'tv-folders-order',
    role: ['admin', 'developer'],
    meta: {
      loadState: true,
      requiresAuth: true,
      isAuthView: false,
      parentGroup: 'tv',
    },
    component: () => import('@/views/tv/folders/Order'),
  },
];
