export default [
  {
    path: '/stories/licenses',
    name: 'stories-licenses-index',
    role: ['admin', 'developer'],
    meta: {
      loadState: true,
      requiresAuth: true,
      isAuthView: false,
      parentGroup: 'stories',
    },
    component: () => import('@/views/stories/licenses/Index'),
  },
  {
    path: '/stories/licenses/create',
    name: 'stories-license-create',
    role: ['admin', 'developer'],
    meta: {
      loadState: true,
      requiresAuth: true,
      isAuthView: false,
      parentGroup: 'stories',
    },
    component: () => import('@/views/stories/licenses/Create'),
  },
  {
    path: '/stories/licenses/edit/:id',
    name: 'stories-license-edit',
    role: ['admin', 'developer'],
    meta: {
      loadState: true,
      requiresAuth: true,
      isAuthView: false,
      parentGroup: 'stories',
    },
    component: () => import('@/views/stories/licenses/Edit'),
  },
  {
    path: '/stories/licenses/order',
    name: 'stories-licenses-order',
    role: ['admin', 'developer'],
    meta: {
      loadState: true,
      requiresAuth: true,
      isAuthView: false,
      parentGroup: 'stories',
    },
    component: () => import('@/views/stories/licenses/Order'),
  },
];
