export default [
  {
    path: '/users',
    name: 'users-index',
    role: ['admin', 'developer', 'customer_success_agent'],
    meta: {
      loadState: true,
      requiresAuth: true,
      isAuthView: false,
      parentGroup: 'general',
    },
    component: () => import('@/views/general/users/Index'),
  },
  {
    path: '/users/details/:id',
    name: 'user-details',
    role: ['admin', 'developer', 'customer_success_agent'],
    meta: {
      loadState: true,
      requiresAuth: true,
      isAuthView: false,
      parentGroup: 'general',
    },
    component: () => import('@/views/general/users/Details'),
    props: true,
  },
];
