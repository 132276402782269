import initialState from '@/core/store/initialState';
import { isObj, isArr, isNull } from '@/core/utils';

/**
* @description Is valid
* @param folders
* @returns {boolean}
*/
const isValid = (folders) => isObj(folders)
  && isNull(folders.draft)
  && isNull(folders.filters)
  && isArr(folders.items)
  && isNull(folders.size)
  && isObj(folders.activeItem);

/**
* @description Init state
* @param initialState
* @returns {*}
*/
const initState = (initialState) => {
  if (!isValid(initialState)) {
    throw Error('Invalid initial stories folders state');
  }

  const { items, size, activeItem, draft, filters } = initialState;
  return {
    items,
    size,
    activeItem,
    draft,
    filters,
  };
};

/**
* @description Getters
* @type {*}
*/
export const getters = {
  items: ({ items }) => items,
  size: ({ size }) => size,
  activeItem: ({ activeItem }) => activeItem,
  draft: ({ draft }) => draft,
  filters: ({ filters }) => filters,
};

const actions = {
  setDraft: ({ commit }, nextDraft) => {
    commit('DRAFT', nextDraft);
  },
  resetDraft: ({ commit }) => {
    commit('DRAFT', null);
  },
  setFilters: ({ commit }, nextFilters) => {
    commit('FILTER', nextFilters);
  },
  reset: ({ commit }) => (
    commit('SET', initialState.storiesFolders)
  ),
  set: ({ commit }, folders) => {
    commit('SET', folders);
  },
};

const mutations = {
  SET(state, folders) {
    state.token = folders.token;
    state.user = folders.user;
    state.role = folders.role;
  },
  DRAFT(state, draft) {
    state.draft = draft;
  },
  FILTER(state, filters) {
    state.filters = filters;
  },
};

export default initialState => ({
  namespaced: true,
  state: initState(initialState),
  getters,
  actions,
  mutations,
});
