const getters = {
  breadcrumbs(state) {
    return state.breadcrumbs;
  },
  page(state) {
    return state.page;
  },
  offset(state) {
    return state.offset;
  },
  menu(state) {
    return state.menu;
  },
  mainMenu(state) {
    return state.mainMenu;
  },
  pageTitle(state) {
    const last = state.breadcrumbs[state.breadcrumbs.length - 1];
    if (last && last.title) {
      return last.title;
    }
  },
};

const actions = {
  setBreadcrumb: (state, payload) => {
    state.commit('SET', payload);
  },
  setPaginationOffset: (state, payload) => {
    state.commit('SETOFFSET', payload);
  },
  setPaginationPage: (state, payload) => {
    state.commit('SETPAGE', payload);
  },
  setSelectedMenu: (state, payload) => {
    // can only handle sub menu selection
    state.commit('SETMENUE', payload);
  },
  setSelectedMainMenu: (state, payload) => {
    state.commit('SETMAINMENUE', payload);
  },
};

const mutations = {
  SET(state, payload) {
    state.breadcrumbs = payload;
  },
  SETOFFSET(state, payload) {
    state.offset = payload;
  },
  SETPAGE(state, payload) {
    state.page = payload;
  },
  SETMENUE(state, payload) {
    state.menu = payload;
  },
  SETMAINMENUE(state, payload) {
    state.mainMenu = payload;
  },
};

export default {
  namespaced: true,
  state: {
    breadcrumbs: [],
    page: 1,
    offset: 0,
    menu: null,
    mainMenu: null
  },
  getters,
  actions,
  mutations,
};
