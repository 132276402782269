import VuexPersistence from 'vuex-persist';
import initialState from './initialState';

const { localStorage } = window;

export default new VuexPersistence({
  storage: localStorage,
  key: 'kdj_bo_app',
  reducer: (state) => ({
    ...state,
    auth: {
      ...initialState.auth,
      token: state.auth.token,
      userRoles: state.auth.userRoles,
      permissions: state.auth.permissions
    },
    config: {
      ...initialState.config,
      regions: state.config.regions,
      roles: state.config.roles,
      countries: state.config.countries,
      thematics: state.config.thematics,
      languages: state.config.languages,
      rightsType: state.config.rightsType,
      countryRegionList: state.config.countryRegionList,
    },
    tvOwners: {
      ...initialState.tvOwners,
      draft: state.tvOwners.draft,
    },
    tvLicenses: {
      ...initialState.tvLicenses,
      draft: state.tvLicenses.draft,
    },
    tvFolders: {
      ...initialState.tvFolders,
      draft: state.tvFolders.draft,
    },
    tvVideos: {
      ...initialState.tvVideos,
      draft: state.tvVideos.draft,
    },
    storiesOwners: {
      ...initialState.storiesOwners,
      draft: state.storiesOwners.draft,
    },
    storiesLicenses: {
      ...initialState.storiesLicenses,
      draft: state.storiesLicenses.draft,
    },
    storiesFolders: {
      ...initialState.storiesFolders,
      draft: state.storiesFolders.draft,
    },
    storiesBooks: {
      ...initialState.storiesBooks,
      draft: state.storiesBooks.draft,
    },
  }),
});
