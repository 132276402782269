import axios from 'axios';

const baseUrl = import.meta.env.VITE_API_TV_V2;
const headers = {
  'Content-Type': 'application/json; Charset=UTF-8',
};

export default {
  getLicensesList() {
    return axios({
      method: 'get',
      url: `${baseUrl}/licenses`,
      headers,
    });
  },
  getUpdatedLicensesList(options = {}) {
    return axios({
      method: 'get',
      url: `${baseUrl}/all-licenses`,
      headers,
      params: {
        offset: 0,
        fetchLimit: options.limit ? options.limit : 1000
      }
    });
  },
  getLicenses(options = null) {
    let params = null;

    if (options) {
      const {
        offset = 0,
        limit = 10,
        keyword = '',
        isTaken = '',
        ownerId = null,
        type = '',
        sort = '',
      } = options;

      params = {
        offset,
        fetchLimit: limit,
        search: keyword,
      };

      if (isTaken !== '') {
        params.isTaken = isTaken;
      }

      if (ownerId) {
        params.ownerId = ownerId;
      }

      if (sort) {
        params.sortColumns = type;
        params.sortColumnOrders = sort;
      }
    }

    return axios({
      method: 'get',
      url: `${baseUrl}/licenses`,
      headers,
      params,
    });
  },
  getLicense(id) {
    const params = {
      licenseId: id,
    };

    return axios({
      method: 'get',
      url: `${baseUrl}/license`,
      headers,
      params,
    });
  },
  createLicense(data) {
    return axios({
      method: 'post',
      url: `${baseUrl}/license`,
      headers,
      data,
    });
  },
  updateLicense(data) {
    return axios({
      method: 'put',
      url: `${baseUrl}/license`,
      headers,
      data,
    });
  },
  deleteLicense(id) {
    const params = {
      licenseId: id,
    };

    return axios({
      method: 'delete',
      url: `${baseUrl}/license`,
      headers,
      params,
    });
  },
  getLicensesOrder() {
    return axios({
      method: 'get',
      url: `${baseUrl}/licenses/order`,
      headers,
    });
  },
  updateLicensesOrder(order) {
    const data = {
      reorders: order, // DEV NOTE: will be modified to send only the array itself
    };

    return axios({
      method: 'put',
      url: `${baseUrl}/licenses/reorder`,
      headers,
      data,
    });
  },

  downLoadLicense() {
    return axios({
      method: 'get',
      url: `${baseUrl}/license/csv`,
      headers,
    });
  }
};
