import {
  ageRange, languages,
  activityStatuses, thematics,
  products, couponTypes,
  couponDuration, couponStatuses,
  subscriptionStatuses,
  plan,
  couponSearchTypes,
  rightsType,
  colors
} from '@/core/configs/basic.config';
import TvOwners from '@/core/configs/tv.owners';
import TvLicenses from '@/core/configs/tv.licenses';
import TvFolders from '@/core/configs/tv.folders';
import TvVideos from '@/core/configs/tv.videos';
import TvStatistics from '@/core/configs/tv.statistics';
import StoriesOwners from '@/core/configs/stories.owners';
import StoriesLicenses from '@/core/configs/stories.licenses';
import StoriesFolders from '@/core/configs/stories.folders';
import StoriesBooks from '@/core/configs/stories.books';
import StoriesStatistics from '@/core/configs/stories.statistics';
import GeneralCoupons from '@/core/configs/general.coupons';
import GeneralUsers from '@/core/configs/general.users';

const env = process.env.NODE_ENV;

export const isDev = env === 'development';
export const isStage = env === 'staging';
export const isProd = env === 'production';
export const isLocal = env === 'localhost';

export default {
  install: (app) => {
    const config = {
      env,
      apis: {
        tv: import.meta.env.VITE_API_TV,
        stories: import.meta.env.VITE_API_STORIES,
        general: import.meta.env.VITE_API_GENERAL,
      },
      assets: {
        storiesCoverImages: import.meta.env.VITE_STORIES_COVER_IMAGES,
        audiobooks: import.meta.env.VITE_AUDIOBOOK_CLOUDFRONT,
        tvCoverImage: import.meta.env.VITE_TV_COVER_IMAGE_CLOUDFRONT,
        tvThumbNail: import.meta.env.VITE_TV_COVER_THUMBNAIL_CLOUDFRONT,
        tvFolderImages: import.meta.env.VITE_TV_FOLDER_COVER_IMAGES,
        tvIncomingVideoS3: import.meta.env.VITE_TV_INCOMING_VIDEO_S3,
        tvUpdatedIncomingVideoS3: import.meta.env.VITE_TV_UPDATED_INCOMING_VIDEO_S3
      },
      tv: {
        ageRange,
        languages,
        statuses: activityStatuses,
        thematics: thematics.filter(({ parentGroup }) => parentGroup === 'tv'),
        owners: TvOwners,
        licenses: TvLicenses,
        folders: TvFolders,
        videos: TvVideos,
        statistics: TvStatistics,
        rightsType,
      },
      stories: {
        ageRange,
        languages,
        statuses: activityStatuses,
        thematics: thematics.filter(({ parentGroup }) => parentGroup === 'stories'),
        owners: StoriesOwners,
        licenses: StoriesLicenses,
        folders: StoriesFolders,
        books: StoriesBooks,
        statistics: StoriesStatistics,
      },
      general: {
        products,
        couponTypes,
        couponDuration,
        couponStatuses,
        coupons: GeneralCoupons,
        users: GeneralUsers,
        subscriptionStatuses,
        plan,
        couponSearchTypes,
        colors
      },
    };

    app.config.globalProperties.APP_CONFIG = config;
  }
};
