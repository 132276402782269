export const indexColumns = [
  {
    type: 'title',
    value: 'title',
    label: 'Title',
    isSortable: true,
  },
  {
    type: 'videoThumbnail',
    value: 'videoThumbnail',
    label: 'Thumbnail',
    isSortable: false,
  },
  {
    type: 'languageIds',
    value: 'languageIds',
    label: 'Language',
    isSortable: true,
  },
  {
    type: 'folderName',
    value: 'folderName',
    label: 'Folders',
    isSortable: true,
  },
  {
    type: 'isPremium', // DEV NOTE: request change on this
    value: null,
    label: 'Premium',
    isSortable: true,
  },
  {
    type: 'isActive', // DEV NOTE: request change on this
    value: null,
    label: 'Active',
    isSortable: true,
  },
  {
    type: 'actions',
    value: null,
    label: 'Actions',
    isSortable: false,
  },
];

export const uploadColumns = [
  {
    type: 'title',
    value: 'title',
    label: 'Title',
    isSortable: false,
  },
  {
    type: 'video',
    value: 'video',
    label: 'Video(s)',
    isSortable: false,
  },
  {
    type: 'source',
    value: 'source',
    label: 'Source',
    isSortable: false,
  },
  {
    type: 'license',
    value: 'license',
    label: 'License',
    isSortable: false,
  },
  // {
  //   type: 'added',
  //   value: 'added',
  //   label: 'Added',
  //   isSortable: true,
  // },
  // {
  //   type: 'updated',
  //   value: 'updated',
  //   label: 'Updated',
  //   isSortable: true,
  // },
  {
    type: 'status',
    value: 'status',
    label: 'Status',
    isSortable: false,
  },
  {
    type: 'actions',
    value: null,
    label: '',
    isSortable: false,
  },
];

export const editorTabs = [
  {
    id: 0,
    slug: 'general',
    label: 'General',
  },
  {
    id: 1,
    slug: 'language',
    label: 'Language',
  },
];

const sharedConfig = {
  clearable: true,
  variant: 'outlined',
  // DEV NOTE: must be 'false' for 'v-autocomplete'
  hideDetails: true,
};

export const filterConfig = [
  {
    name: 'keyword',
    controlType: 'text',
    label: 'Search',
    prependInnerIcon: 'mdi-magnify',
    ...sharedConfig
  },
  {
    // DEV NOTE: due to bug in 'autocmplete' temporary use 'autocomplete'
    name: 'license',
    controlType: 'autocomplete',
    label: 'License',
    itemTitle: 'title',
    itemValue: 'id',
    items: [],
    ...sharedConfig,
    hideDetails: false
  },
  {
    name: 'language',
    controlType: 'autocomplete',
    label: 'Language',
    itemTitle: 'label',
    itemValue: 'id',
    items: [],
    ...sharedConfig,
    hideDetails: false
  },
  {
    name: 'folders',
    controlType: 'autocomplete',
    label: 'Folder',
    itemTitle: 'title',
    itemValue: 'id',
    items: [],
    ...sharedConfig,
    hideDetails: false
  },
  {
    name: 'owners',
    controlType: 'autocomplete',
    label: 'Owner',
    itemTitle: 'name',
    itemValue: 'id',
    items: [],
    ...sharedConfig,
    hideDetails: false
  },
  {
    name: 'activity',
    controlType: 'autocomplete',
    label: 'Status',
    itemTitle: 'label',
    itemValue: 'id',
    items: [],
    ...sharedConfig,
    hideDetails: false
  },
  {
    name: 'premium',
    controlType: 'checkbox',
    label: 'Premium',
    ...sharedConfig,
  },
  // {
  //   name: 'dialogue',
  //   controlType: 'checkbox',
  //   label: 'Non-dialogue',
  //   ...sharedConfig,
  // },
];

export default { indexColumns, editorTabs, uploadColumns, filterConfig };
