import { isProd } from '@/core/configs';
import { isNull, isUndef } from '@/core/utils';

export default {
  methods: {
    generateTestClass(modifier, dynamicIdentifier) {
      const root = 'test';
      const isValidIdentifier = !isNull(dynamicIdentifier) && !isUndef(dynamicIdentifier);
      const lowerCasedModifier = modifier.toLowerCase();

      if (isProd) {
        return '';
      }

      if (isValidIdentifier) {
        return `${root}--${lowerCasedModifier}-${dynamicIdentifier}`;
      }

      return `${root}--${lowerCasedModifier}`;
    }
  }
};
