export default [
  {
    path: '/stories/owners',
    name: 'stories-owners-index',
    role: ['admin', 'developer'],
    meta: {
      loadState: true,
      requiresAuth: true,
      isAuthView: false,
      parentGroup: 'stories',
    },
    component: () => import('@/views/stories/owners/Index'),
  },
  {
    path: '/stories/owners/create',
    name: 'stories-owner-create',
    role: ['admin', 'developer'],
    meta: {
      loadState: true,
      requiresAuth: true,
      isAuthView: false,
      parentGroup: 'stories',
    },
    component: () => import('@/views/stories/owners/Create'),
  },
  {
    path: '/stories/owners/edit/:id',
    name: 'stories-owner-edit',
    role: ['admin', 'developer'],
    meta: {
      loadState: true,
      requiresAuth: true,
      isAuthView: false,
      parentGroup: 'stories',
    },
    component: () => import('@/views/stories/owners/Edit'),
  },
];
