export const indexColumns = [
  {
    type: 'name',
    value: 'name',
    label: 'Name',
    isSortable: true,
  },
  {
    type: 'numOfLicenses',
    value: 'numOfLicenses',
    label: 'Number of license(s)',
    isSortable: true,
  },
  {
    type: 'numOfBooks',
    value: 'numOfBooks',
    label: 'Number of book(s)',
    isSortable: true,
  },
  {
    type: 'actions',
    value: null,
    label: 'Actions',
    isSortable: false,
  },
];

export const editorLicensesColumns = [
  {
    type: 'name',
    value: 'title',
    label: 'Name',
  },
  {
    type: 'numOfBooks',
    value: 'numOfBooks',
    label: 'Number of book(s)',
  },
  {
    type: 'actions',
    value: null,
    label: 'Actions',
  },
];

const sharedConfig = {
  clearable: true,
  variant: 'outlined',
  // DEV NOTE: must be 'false' for 'v-autocomplete'
  hideDetails: true,
};

export const filterConfig = [
  {
    name: 'keyword',
    controlType: 'text',
    label: 'Search',
    prependInnerIcon: 'mdi-magnify',
    ...sharedConfig
  },
];

export default { indexColumns, editorLicensesColumns, filterConfig };
